import React, { useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import styles from "./DateInput.module.css";
import tr from "date-fns/locale/tr";
// import en from "date-fns/locale/en-US";

const FieldDate = ({ control, name, label, valid, register, errors, require, type, error, placeholder, setState, onChange, disables, ...other }) => {
	let newRegister = register ? register(name) : {};
	const { t, i18n } = useTranslation();

	// if (i18n.language == "en") registerLocale("en", en)
	// else
	registerLocale("tr", tr);
	// console.log({ tr, en, i18n: i18n.language });
	const isWeekday = (date) => {
		const day = date.getDay();
		return day !== 0 && day !== 6;
	};
	return (
		<Form.Group className="form-group ">
			<div className={"d-flex justify-content-between"}>
				{label ? (
					<label htmlFor={name}>
						{!disables?.translate?.label ? compareTranslate(t, label) : label}
						{require ? " *" : ""}
					</label>
				) : (
					""
				)}
			</div>

			<div className="input-wrapper">
				{control ? (
					<Controller
						control={control}
						ref={newRegister}
						name={name}
						render={({ field: { onChange: change, value } }) => {
							function handleChange(params) {
								onChange?.(params);
								change(params);
							}
							return (
								<div>
									<ReactDatePicker
										showTimeSelect={!other.showMonthYearPicker}
										// timeFormat="p"
										timeFormat="HH:mm"
										timeIntervals={15}
										showTimeInput={!other.showMonthYearPicker}
										dateFormat="Pp"
										// dateFormat="MMMM d, yyyy HH:mm"
										showYearDropdown
										className="form-control"
										id={name}
										name={name}
										placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
										onChange={handleChange}
										selected={value}
										locale={i18n.language}
										{...other}
										popperPlacement="bottom">
										{/* {!disables?.now ? <Badge className={styles.now} onClick={() => handleChange(new Date())}>Now!</Badge> : ""} */}
									</ReactDatePicker>
								</div>
							);

							// console.log({ onChange, value, options },'');
							// return <Select className="custom-selector" placeholder={label} options={newOptions} value={value} onChange={handleChange} {...other} />;
						}}
					/>
				) : (
					<div>
						<ReactDatePicker
							// showTimeSelect
							// timeFormat="p"
							timeFormat="HH:mm"
							timeIntervals={15}
							showTimeInput={!other.showMonthYearPicker}
							dateFormat="Pp"
							// dateFormat="MMMM d, yyyy"
							// dateFormat="MMMM d, yyyy HH:mm"
							showYearDropdown={!other.showMonthYearPicker}
							className="form-control"
							id={name}
							name={name}
							placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
							onChange={onChange}
							locale={i18n.language}
							{...other}
							{...newRegister}
							popperPlacement="bottom">
							{!disables?.now && !other.showMonthYearPicker ? (
								<Badge className={styles.now} onClick={() => onChange(new Date())}>
									Now!
								</Badge>
							) : (
								""
							)}
						</ReactDatePicker>
					</div>
				)}
			</div>
			{errors?.[name] ? (
				<div className={`error-text mt-1  `}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</div>
			) : (
				""
			)}
		</Form.Group>
	);
};
export default FieldDate;
