import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, initial, PAYMENT_METHOD_OBJECT, PAYMENT_METHOD_ARRAY } from "../enum";
import {
	DRINK_DATA,
	DRINK_DATA_OBJECT,
	INVOICE_DATA,
	INVOICE_DATA_OBJECT,
	LANGUAGE_DATA,
	LANGUAGE_DATA_OBJECT,
	OPERATION,
	OPERATION_SAID,
	PAYMENT_PERIOD,
	PAYMENT_PERIOD_OBJECT,
	ROLES,
	STATUSES,
	STATUSES_OBJECT,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";
import LanguageSelector, { convertLanguagesData } from "containers/Language/LanguageSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import ProductSelector, { convertProductData, convertProductsData } from "containers/Product/ProductSelector";
import CategorySelector, { convertCategoriesData, convertCategoryData } from "containers/Category/CategorySelector";
import { OPERATION_OBJECT, OPERATION_SAID_OBJECT } from "../../../enumerations";
import moment from "moment";
import FieldDate from "components/FieldDate";
import RegionSelector, { convertRegionData } from "containers/Regions/RegionSelector";
import ModalLayout from "components/ModalLayout";
import PrintRoomNumbers from "./PrintRoomNumbers";
import configs from "configs";
import QrCodeComponent from "components/QrCodeComponent";
import DownloadQrCodes from "./DownloadQrCodes";
import RoomNumbersQrCode from "./RoomNumbersQrCode";
import DownloadExcelAllRoomNumbers from "./DownloadExcelAllRoomNumbers/index";

const Add = () => {
	const { t } = useTranslation();
	const location = useLocation();

	const [toggleQrCode, setToggleQrCode] = useState();

	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;
	const previousQueryParams = new URLSearchParams(previousQuery || {});

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	const navigation = useNavigate();
	let { id } = useParams();
	const URL_ID = id ? id : ""; // ----------------------------------------------------------------------------- Form,
	const methods = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
		...(!URL_ID && {
			defaultValues: {
				[fieldNames.serviceCharge]: true,
				[fieldNames.orderLimit]: 6,
				[fieldNames.commission]: 15,
			},
		}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = methods;

	const watchRoomCount = watch(fieldNames.roomCount);
	const specialRoomWatch = watch(fieldNames.specialRoom);
	const missafirWatch = watch(fieldNames.missafir);

	const checkDateFormat = (date) => {
		let def = "2017-03-13";
		try {
			moment(date);
			return date;
		} catch (error) {
			return def;
		}
	};
	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		// let selectedStatus = STATUSES.find(
		//   (item) => item.value === params.isActive
		// );
		console.log({ params });
		// handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });
		let date = "2017-03-13";
		let paymentMethod = PAYMENT_METHOD_OBJECT[params.paymentMethod];
		if (paymentMethod) paymentMethod.label = t(paymentMethod.label);
		const resetParam = {
			...params,
			...params.payment,
			region: params.region && convertRegionData(params.region),
			// branch: convertBranchData(params.branch),
			defaultLanguage: params.defaultLanguage && LANGUAGE_DATA_OBJECT[params.defaultLanguage],
			languages: convertLanguagesData(params?.languages),
			// invoice: INVOICE_DATA_OBJECT[params.invoice],
			alcohol: params.alcohol,
			specialRoom: !!params?.roomNumbers?.length,
			// specialRoom: !!params?.qrCode?.roomNumbersPath?.length,
			// drink: DRINK_DATA_OBJECT[params.drink],
			// role: { label: params?.role, value: params?.role },
			isActive: STATUSES_OBJECT[params.isActive],
			// exceptProducts: convertProductsData(params?.exceptProducts),
			exceptCategories: params.exceptCategories.map((x) => ({
				categories: convertCategoriesData(x.categories),
				startTime: x?.startTime && new Date(moment(checkDateFormat(x?.startTime ? date + " " + x?.startTime : date)).format()),
				endTime: x?.endTime && new Date(moment(checkDateFormat(x?.endTime ? date + " " + x?.endTime : date)).format()),
			})),
			exceptProducts: params.exceptProducts.map((x) => ({
				products: convertProductsData(x.products),
				startTime: x?.startTime && new Date(moment(checkDateFormat(x?.startTime ? date + " " + x?.startTime : date)).format()),
				endTime: x?.endTime && new Date(moment(checkDateFormat(x?.endTime ? date + " " + x?.endTime : date)).format()),
			})),
			includeProducts: params.includeProducts.map((x) => ({
				products: convertProductsData(x.products),
				startTime: x?.startTime && new Date(moment(checkDateFormat(x?.startTime ? date + " " + x?.startTime : date)).format()),
				endTime: x?.endTime && new Date(moment(checkDateFormat(x?.endTime ? date + " " + x?.endTime : date)).format()),
			})),
			// exceptCategories: convertCategoriesData(params?.exceptCategories),
			users: params.users.map((x) => ({
				email: x.email,
				id: x.id,
				isActive: STATUSES_OBJECT[x.isActive],
				isEmailNotificationActive: !!x.isEmailNotificationActive,
				isPhoneNotificationActive: !!x.isPhoneNotificationActive,
				lastName: x.lastName,
				name: x.name,
				phoneNumber: x.phoneNumber,
			})),
			mpu: params.mpu?.map((x) => ({
				...x,
				categories: convertCategoriesData(x?.categories),
				delivery: DRINK_DATA_OBJECT[x?.delivery],
				operation: OPERATION_OBJECT[x?.operation],
				operationSide: OPERATION_SAID_OBJECT[x?.operationSide],
				startTime: x?.startTime && new Date(moment(checkDateFormat(x?.startTime ? date + " " + x?.startTime : date)).format()),
				endTime: x?.endTime && new Date(moment(checkDateFormat(x?.endTime ? date + " " + x?.endTime : date)).format()),
			})),
			paymentPeriod: PAYMENT_PERIOD_OBJECT[params?.paymentPeriod],
			paymentMethod,
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		};
		console.log({ resetParam });
		reset(resetParam);
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}_${URL_ID}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const { missafirRooms, isCashActive, isCardActive, isOnlineActive, users, specialRoom, roomNumbers, ...values } = getValues();
		let date = "2017-03-13";

		console.log({ users, values });
		let reqData = {
			...values,
			isActive: values.isActive?.value,
			roomNumbers: specialRoom ? roomNumbers?.filter((x) => x && x.trim("")) : [],

			// branch: values.branch.value,
			region: values?.region?.value,
			defaultLanguage: values?.defaultLanguage?.value,
			languages: values.languages?.map((x) => x.value),
			// invoice: values?.invoice?.value,
			// drink: values.drink.value,
			isCardActive: !!values.isCardActive,
			isCashActive: !!values.isCashActive,
			isOnlineActive: !!values.isOnlineActive,
			isOrderLimit: !!values.isOrderLimit,
			isFastDelivery: !!values.isFastDelivery,
			isNewProduct: !!values.isNewProduct,
			serviceCharge: !!values.serviceCharge,
			alcohol: !!values.alcohol,
			coldDrink: !!values.coldDrink,
			paymentPeriod: values?.paymentPeriod?.value,
			paymentMethod: values?.paymentMethod?.value,
			...(!URL_ID && {
				users: users.map((x) => ({
					email: x.email,
					...(x.id && { id: x.id }),
					isActive: STATUSES.find((item) => item.value === x.isActive?.value)?.value,
					isEmailNotificationActive: !!x.isEmailNotificationActive,
					isPhoneNotificationActive: !!x.isPhoneNotificationActive,
					...(x.password && { password: x.password }),
					lastName: x.lastName,
					name: x.name,
					phoneNumber: x.phoneNumber,
				})),
			}),
			exceptProducts: values?.exceptProducts
				?.filter((x) => Object.values(x)?.some((y) => y))
				?.map((x) => ({
					products: x.products?.map((x) => x?.value),
					startTime: x?.startTime && moment(x?.startTime).format("hh:mm A"),
					endTime: x?.endTime && moment(x?.endTime).format("hh:mm A"),
				})),
			includeProducts: values?.includeProducts
				?.filter((x) => Object.values(x)?.some((y) => y))
				?.map((x) => ({
					products: x.products?.map((x) => x?.value),
					startTime: x?.startTime && moment(x?.startTime).format("hh:mm A"),
					endTime: x?.endTime && moment(x?.endTime).format("hh:mm A"),
				})),
			exceptCategories: values?.exceptCategories
				?.filter((x) => Object.values(x)?.some((y) => y))
				?.map((x) => ({
					categories: x.categories?.map((x) => x?.value),
					startTime: x?.startTime && moment(x?.startTime).format("hh:mm A"),
					endTime: x?.endTime && moment(x?.endTime).format("hh:mm A"),
				})),
			payment: {
				isOnlineActive: !!isOnlineActive,
				isCardActive: !!isCardActive,
				isCashActive: !!isCashActive,
			},
			mpu: values?.mpu?.map((x) => ({
				...x,
				categories: x?.categories?.map((y) => y.value),
				delivery: x?.delivery?.value,
				operation: x?.operation?.value,
				operationSide: x?.operationSide?.value,
				startTime: x?.startTime && moment(x?.startTime).format("hh:mm A"),
				endTime: x?.endTime && moment(x?.endTime).format("hh:mm A"),
			})),
			// image: values.image._id,
		};

		if (missafirRooms) {
			for (let i = 0; i < roomNumbers?.length; i++) {
				const curr = roomNumbers?.[i];

				if (curr && missafirRooms?.[i]?.address) {
					reqData[fieldNames.missafirRooms] = reqData[fieldNames.missafirRooms] || [];
					reqData[fieldNames.missafirRooms][i] = reqData[fieldNames.missafirRooms][i] || {};
					reqData[fieldNames.missafirRooms][i] = {
						roomNumber: curr,
						address: missafirRooms?.[i]?.address,
					};
				}
				// reqData[fieldNames.missafirRooms][i] = reqData[fieldNames.missafirRooms][i] || { roomNumber: curr };
			}
		}
		console.log({ reqData, values });
		mutate(reqData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.name} ` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const defaultData = { name: "" };

	const addNewUser = () => {
		const values = getValues();
		const newData = values[fieldNames.users] ? [...values[fieldNames.users], { ...defaultData }] : [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.users, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const defaultDataMpu = { categories: [] };

	const addNewMpu = () => {
		const values = getValues();
		const newData = values[fieldNames.mpu] ? [...values[fieldNames.mpu], { ...defaultDataMpu }] : [{ ...defaultDataMpu }, { ...defaultDataMpu }];
		console.log({ newData });
		setValue(fieldNames.mpu, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const defaultDataExceptCategory = { category: null };

	const addNewExceptCategory = () => {
		const values = getValues();
		const newData = values[fieldNames.exceptCategories]
			? [...values[fieldNames.exceptCategories], { ...defaultDataExceptCategory }]
			: [{ ...defaultDataExceptCategory }, { ...defaultDataExceptCategory }];
		console.log({ newData });
		setValue(fieldNames.exceptCategories, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const defaultDataExceptProduct = { product: null };

	const addNewExceptProduct = () => {
		const values = getValues();
		const newData = values[fieldNames.exceptProducts]
			? [...values[fieldNames.exceptProducts], { ...defaultDataExceptProduct }]
			: [{ ...defaultDataExceptProduct }, { ...defaultDataExceptProduct }];
		console.log({ newData });
		setValue(fieldNames.exceptProducts, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const addIncludeProducts = () => {
		const values = getValues();
		const newData = values[fieldNames.includeProducts]
			? [...values[fieldNames.includeProducts], { ...defaultDataExceptProduct }]
			: [{ ...defaultDataExceptProduct }, { ...defaultDataExceptProduct }];
		console.log({ newData });
		setValue(fieldNames.includeProducts, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const handleShowQrCodeModal = () => setToggleQrCode((p) => !p);
	const qrCodeUrl = `${configs.data.WEBSITE}?ouid=${dataById?.data?.data?.uuid}`;
	console.log({ qrCodeUrl });
	return (
		<FormProvider {...methods}>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								{/* <DownloadQrCodes {...{ qrCodeUrl, roomNumbers: dataById?.data?.data?.roomNumbers }} /> */}
								{dataById?.data ? (
									<Form.Group className="form-group ">
										<div className="input-wrapper">
											<div className="form-check">
												<h4>Pleksi QR</h4>
												{/* <img src={dataById?.data?.data?.qrCode?.path} /> */}
												<QrCodeComponent
													{...{
														text: qrCodeUrl,
														options: {
															width: 196,
															height: 196,
														},
													}}
												/>
											</div>

											<div>
												<h4>Magnet QR</h4>
												{/* <img src={dataById?.data?.data?.qrCode?.magnetPath} /> */}
												<QrCodeComponent
													{...{
														text: qrCodeUrl + "&isMagnet=true",
														options: {
															width: 196,
															height: 196,
														},
													}}
												/>
											</div>

											<div>
												<h4>___________________________</h4>
												<h4>Katalog QR</h4>
												{/* <img src={dataById?.data?.data?.qrCode?.catalogPath} /> */}
												<QrCodeComponent
													{...{
														text: qrCodeUrl + "&isCatalog=true",
														options: {
															width: 196,
															height: 196,
														},
													}}
												/>
											</div>
											<div className="mb-4"></div>

											<div className="border-bottom mb-3"></div>
											{`room number counts (${dataById?.data?.data?.roomNumbers?.length})`}
											<div className="mb-4"></div>

											<Row>
												{dataById?.data?.data?.roomNumbers?.slice(0, 2)?.map((x) => {
													return (
														<Col xs={6}>
															<h4>roomNumber : {x}</h4>
															{/* <img width={196} src={x.path} /> */}
															<QrCodeComponent
																{...{
																	text: qrCodeUrl + `&roomNumber=${x}`,
																	roomNumber: x,
																	options: {
																		width: 196,
																		height: 196,
																	},
																}}
															/>
														</Col>
													);
												})}
											</Row>

											<ModalLayout title={`Room Numbers (${dataById?.data?.data?.roomNumbers?.length})`} show={toggleQrCode} setShow={setToggleQrCode}>
												<Row>
													{dataById?.data?.data?.roomNumbers?.map((x) => {
														return (
															<Col sm={6} md={6} lg={6} className="my-3">
																<h6> {x}</h6>
																{/* <img className="img-fluid" src={x.path} /> */}
																<QrCodeComponent
																	{...{
																		text: qrCodeUrl + `&roomNumber=${x}`,
																		roomNumber: x,
																		options: {
																			width: 196,
																			height: 196,
																		},
																	}}
																/>
															</Col>
														);
													})}
												</Row>
											</ModalLayout>
											<div className="mt-5 text-center">
												{
													// dataById?.data?.data?.roomNumbers?.length > 2 ?
													// <Button onClick={handleShowQrCodeModal}>
													//   {"show more"}
													// </Button>
													dataById?.data?.data ? <RoomNumbersQrCode {...{ dataById, qrCodeUrl }} /> : ""
													// : ""
												}
											</div>
										</div>
										<div className="border-bottom mt-3"></div>
									</Form.Group>
								) : (
									""
								)}
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name",
													errors,
												}}
											/>
											{/* <FieldText
                        require
                        {...{
                          name: fieldNames.iban,
                          register,
                          placeholder: "iban",
                          label: "iban",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.ibanName,
                          register,
                          placeholder: "ibanName",
                          label: "ibanName",
                          errors,
                        }}
                      /> */}
											<FieldText
												require
												{...{
													name: fieldNames.commercialTitle,
													register,
													placeholder: "commercialTitle",
													label: "commercialTitle",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.taxNo,
													register,
													placeholder: "taxNo",
													label: "taxNo",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.taxAdministration,
													register,
													placeholder: "taxAdministration",
													label: "taxAdministration",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.address,
													register,
													placeholder: "address",
													label: "address",
													errors,
												}}
											/>

											{/* <FieldText
                        require
                        {...{
                          name: fieldNames.defaultLanguage,
                          register,
                          placeholder: "defaultLanguage",
                          label: "defaultLanguage",
                          errors,
                        }}
                      /> */}

											<FieldText
												require
												{...{
													name: fieldNames.phoneNumber,
													register,
													placeholder: "phoneNumber",
													label: "phoneNumber",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.roomCount,
													register,
													placeholder: "roomCount",
													label: "roomCount",
													errors,
												}}
											/>

											{+watchRoomCount ? (
												<CheckBox
													{...{
														control,
														name: fieldNames.specialRoom,
														register,
														placeholder: "specialRoom",
														label: "specialRoom",
														errors,
													}}
												/>
											) : (
												""
											)}
											{specialRoomWatch ? (
												<CheckBox
													{...{
														control,
														name: fieldNames.missafir,
														register,
														placeholder: "missafir",
														label: "missafir",
														errors,
													}}
												/>
											) : (
												""
											)}
											{specialRoomWatch ? (
												<Row>
													{new Array(+watchRoomCount).fill({})?.map((x, i) => {
														return (
															<Col xs={6} key={`room-number-${i}`}>
																<FieldText
																	require
																	{...{
																		name: `${fieldNames.roomNumbers}[${i}]`,
																		register,
																		placeholder: `Room (${i + 1})`,
																		label: `Room (${i + 1})`,
																		errors,
																	}}
																/>
																{missafirWatch ? (
																	<FieldText
																		{...{
																			control,
																			name: `missafirRooms.[${i}].address`,
																			placeholder: "address",
																			// label: "address ",
																			error: errors.missafirRooms?.[i].address,
																		}}
																	/>
																) : (
																	""
																)}
															</Col>
														);
													})}
												</Row>
											) : (
												""
											)}

											<FieldText
												{...{
													name: fieldNames.setupCost,
													register,
													placeholder: "0",
													type: "number",
													label: "setupCost",
													errors,
												}}
											/>
											<FieldText
												require
												disabled
												{...{
													name: fieldNames.commission,
													register,
													placeholder: "0",
													type: "number",
													label: "commission",
													errors,
												}}
											/>
											<FieldText
												require
												disabled
												{...{
													name: fieldNames.orderLimit,
													register,
													placeholder: "0",
													type: "number",
													label: "orderLimit",
													errors,
												}}
											/>

											<FieldSelect
												isClearable
												{...{
													name: fieldNames.paymentPeriod,
													label: "paymentPeriod",
													// value: value?.[index]?.[fieldNames.user_isActive],
													errors: errors,
													register,
													control,
													options: PAYMENT_PERIOD,
												}}
											/>

											<FieldSelect
												isClearable
												{...{
													name: fieldNames.paymentMethod,
													label: "paymentMethod",
													// value: value?.[index]?.[fieldNames.user_isActive],
													errors: errors,
													register,
													control,
													options: PAYMENT_METHOD_ARRAY,
												}}
											/>

											<CheckBox
												disabled
												{...{
													control,

													name: fieldNames.serviceCharge,
													register,
													placeholder: "serviceCharge",
													label: "serviceCharge",
													errors,
												}}
											/>

											<CheckBox
												{...{
													// Controller,\
													control,
													name: fieldNames.isOrderLimit,
													register,
													label: "isOrderLimit",
													errors,
												}}
											/>

											{/* <CheckBox
												{...{
													control,
													name: fieldNames.missafir,
													register,
													placeholder: "missafir",
													label: "missafir",
													errors,
												}}
											/> */}

											{/* <CheckBox
                      defaultValue=defaultChecked{false}
                        {...{
                          // Controller,
                          control,

                          name: fieldNames.serviceCharge,
                          register,
                          label: "serviceCharge",
                          errors,
                        }}
                      /> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* {missafirWatch ? (
							<div className="col-12 border p-4 mt-4">
								<div className="">
									<Row className="b-1">
										<Col lg={6}>
											<h2 className="">{t("missafirRooms")}</h2>
										</Col>
										<Col lg={6} className="d-flex justify-content-end">
											<Button
												onClick={() => {
													const values = getValues();
													const newData = values[fieldNames.missafirRooms] ? [...values[fieldNames.missafirRooms], {}] : [{}];
													console.log({ newData });
													setValue(fieldNames.missafirRooms, newData);
												}}
												className={"d-flex align-items-center"}
												size="sm">
												<div className={"gallery-add-title"}>Add missafirRooms</div>
												<div className={"ml-1"}>+</div>
											</Button>
										</Col>
									</Row>
									<div className="mt-5">
										<div className="col-12">
											<div className="basic-form"></div>
											<Controller
												name={fieldNames.missafirRooms}
												control={control}
												render={({ field: { onChange, value } }) => {
													console.log({ value });

													return Children.toArray(
														value?.map((locale, index) => {
															const removeClick = (index) => {
																let values = getValues();
																if (!values[fieldNames.missafirRooms]) return;
																const newValues = values[fieldNames.missafirRooms].filter((param, i) => i !== index);
																setValue(fieldNames.missafirRooms, newValues);
															};

															return (
																<Row className="user-row" key={value?.email || index}>
																	<>
																		<Col lg={10}>
																			<h5>missafirRooms-{index + 1}</h5>
																		</Col>
																		<Col lg={2}>
																			<div>
																				<Button
																					color="warning"
																					className={"d-flex align-items-center"}
																					size="sm"
																					onClick={(e) => {
																						e.preventDefault();
																						removeClick(index);
																					}}>
																					remove
																				</Button>
																			</div>
																		</Col>
																		<Col lg={12}>
																			<hr style={{ marginBottom: 0 }} />
																		</Col>
																	</>

																	<FieldText
																		{...{
																			control,
																			name: `missafirRooms.[${index}].roomNumber`,
																			placeholder: "roomNumber",
																			label: "roomNumber ",
																			error: errors.missafirRooms?.[index].roomNumber,
																		}}
																	/>

																	<FieldText
																		{...{
																			control,
																			name: `missafirRooms.[${index}].address`,
																			placeholder: "address",
																			label: "address ",
																			error: errors.missafirRooms?.[index].address,
																		}}
																	/>
																</Row>
															);
														})
													);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						)} */}

						<div className="col-12 border p-4 mt-4">
							<div className="">
								<Row className="b-1">
									<Col lg={6}>
										<h2 className="">Users</h2>
									</Col>
									<Col lg={6} className="d-flex justify-content-end">
										<Button onClick={addNewUser} className={"d-flex align-items-center"} size="sm">
											<div className={"gallery-add-title"}>Add User</div>
											<div className={"ml-1"}>+</div>
										</Button>
									</Col>
								</Row>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form"></div>
										<Controller
											name={fieldNames.users}
											control={control}
											render={({ field: { onChange, value } }) => {
												console.log({ value });

												return Children.toArray(
													(value || [{ ...defaultData }]).map((locale, index) => {
														const values2 = getValues();

														function changeHandle(param) {
															console.log({ param });
															let values = value ? [...value] : [{ ...defaultData }];
															values[index][param.name] = param.value;
															onChange(values);
														}

														const removeClick = (index) => {
															let values = getValues();
															if (!values[fieldNames.users]) return;
															const newValues = values[fieldNames.users].filter((param, i) => i !== index);
															setValue(fieldNames.users, newValues);
														};

														return (
															<Row className="user-row" key={value?.email || index}>
																{true ? (
																	<>
																		<Col lg={10}>
																			<h5>user{index + 1}</h5>
																		</Col>
																		<Col lg={2}>
																			<div>
																				<Button
																					color="warning"
																					className={"d-flex align-items-center"}
																					size="sm"
																					onClick={(e) => {
																						e.preventDefault();
																						removeClick(index);
																					}}>
																					remove
																				</Button>
																			</div>
																		</Col>
																		<Col lg={12}>
																			<hr style={{ marginBottom: 0 }} />
																		</Col>
																	</>
																) : (
																	""
																)}

																<FieldText
																	require
																	{...{
																		control,
																		name: `users.[${index}].name`,
																		placeholder: "firstName",
																		label: "firstName ",
																		error: errors.users?.[index].name,
																	}}
																/>
																<FieldText
																	require
																	{...{
																		control,
																		name: `users.[${index}].lastName`,
																		placeholder: "last name",
																		label: "Last Name ",
																		error: errors.users?.[index].lastName,
																	}}
																/>
																<FieldText
																	require
																	{...{
																		control,
																		name: `users.[${index}].email`,
																		placeholder: "email",
																		label: "Email ",
																		error: errors.users?.[index].email,
																	}}
																/>
																<FieldText
																	{...{
																		control,
																		name: `users.[${index}].phoneNumber`,
																		placeholder: "Mobile Number",
																		label: "Mobile Number ",
																		// value: value?.[index]?.[fieldNames.phoneNumber],
																		errors: errors.users?.[index],
																		//onChange: handleChangeInput
																	}}
																/>
																<FieldText
																	require
																	{...{
																		control,
																		// disabled: URL_ID,
																		name: `users.[${index}].password`,
																		placeholder: "password",
																		label: "Password",
																		// value: value?.[index]?.[fieldNames.password],
																		error: errors.users?.[index].password,
																		// show: showPass,
																		// setShow: setShowPass,
																		//onChange: handleChangeInput
																	}}
																/>
																<FieldText
																	require
																	{...{
																		control,
																		// disabled: URL_ID,
																		name: `users.[${index}].confirm_password`,
																		placeholder: "Confirm Password",
																		label: "Confirm Password",
																		// value: value?.[index]?.[fieldNames.confirm_password],
																		error: errors.users?.[index].confirm_password,
																		// show: showConfirmPass,
																		// setShow: setShowConfirmPass,
																		//onChange: handleChangeInput
																	}}
																/>

																<FieldSelect
																	{...{
																		name: `users.[${index}].isActive`,
																		label: "Status",
																		error: errors.users?.[index].isActive,
																		control,
																		options: STATUSES,
																	}}
																/>
																<CheckBox
																	{...{
																		control,
																		name: `users.[${index}].isEmailNotificationActive`,
																		label: "is Email Notification Active",
																		errors: errors.users?.[index].isEmailNotificationActive,
																	}}
																/>
																<CheckBox
																	{...{
																		control,
																		name: `users.[${index}].isPhoneNotificationActive`,
																		label: "is Phone Notification Active",
																		errors: errors.users?.[index].isPhoneNotificationActive,
																	}}
																/>
															</Row>
														);
													})
												);
											}}
										/>
									</div>
								</div>

								{/* -------------------------------------------------------------------------- MPU */}

								<div className="col-12 border p-4 mt-4">
									<div className="">
										<Row className="b-1">
											<Col lg={6}>
												<h2 className="">Mpu</h2>
											</Col>
											<Col lg={6} className="d-flex justify-content-end">
												<Button onClick={addNewMpu} className={"d-flex align-items-center"} size="sm">
													<div className={"gallery-add-title"}>Add Mpu</div>
													<div className={"ml-1"}>+</div>
												</Button>
											</Col>
										</Row>
										<div className="mt-5">
											<div className="col-12">
												<div className="basic-form"></div>
												<Controller
													name={fieldNames.mpu}
													control={control}
													render={({ field: { onChange, value } }) => {
														console.log({ value });

														return Children.toArray(
															value?.map((locale, index) => {
																const removeClick = (index) => {
																	let values = getValues();
																	if (!values[fieldNames.mpu]) return;
																	const newValues = values[fieldNames.mpu].filter((param, i) => i !== index);
																	setValue(fieldNames.mpu, newValues);
																};

																return (
																	<Row className="user-row" key={value?.email || index}>
																		<>
																			<Col lg={10}>
																				<h5>mpu-{index + 1}</h5>
																			</Col>
																			<Col lg={2}>
																				<div>
																					<Button
																						color="warning"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																			</Col>
																			<Col lg={12}>
																				<hr style={{ marginBottom: 0 }} />
																			</Col>
																		</>

																		<CategorySelector
																			isMulti
																			{...{
																				name: `mpu.[${index}].categories`,
																				register,
																				label: "categories",
																				error: errors.mpu?.[index].categories,
																				control,
																			}}
																		/>

																		{/* <FieldText
                     													   {...{
                     													     control,
                     													     name: fieldNames.mpu.delivery,
                     													     placeholder: "delivery",
                     													     label: "delivery ",
                     													     errors,
                     													   }}
                     													 /> */}

																		<FieldSelect
																			// isClearable
																			{...{
																				label: "delivery",
																				Controller,
																				name: `mpu.[${index}].delivery`,
																				register,
																				error: errors.mpu?.[index].delivery,
																				control,
																				options: DRINK_DATA,
																			}}
																		/>

																		<FieldSelect
																			{...{
																				label: "operation",
																				Controller,
																				name: `mpu.[${index}].operation`,
																				register,
																				error: errors.mpu?.[index].operation,
																				control,
																				options: OPERATION,
																			}}
																		/>

																		<FieldText
																			{...{
																				control,
																				name: `mpu.[${index}].operationUnit`,
																				placeholder: "operationUnit",
																				label: "operationUnit ",
																				error: errors.mpu?.[index].operationUnit,
																			}}
																		/>

																		<FieldSelect
																			{...{
																				label: "operationSide",
																				Controller,
																				name: `mpu.[${index}].operationSide`,
																				register,
																				errors: errors,
																				control,
																				options: OPERATION_SAID,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `mpu.[${index}].startTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "startTime",
																				errors,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `mpu.[${index}].endTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "endTime",
																				errors,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																		/>
																	</Row>
																);
															})
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<Button block type="submit" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
										{URL_ID ? (
											<div>
												<PrintRoomNumbers data={dataById?.data?.data} />

												{/* <DownloadExcelAllRoomNumbers qrCodeUrl={qrCodeUrl} data={dataById?.data?.data} /> */}
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</Col>
						</div>

						<div className="row mt-4">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"İstenmeyen İçecekler"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<CheckBox
												{...{
													control,
													name: fieldNames.alcohol,
													register,
													placeholder: "alcohol",
													label: "alcohol",
													errors,
												}}
											/>

											<CheckBox
												{...{
													control,
													name: fieldNames.coldDrink,
													register,
													placeholder: "coldDrink",
													label: "coldDrink",
													errors,
												}}
											/>
										</div>
									</div>
								</div>
							</Col>
						</div>

						{/* <div className="row mt-4">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{"Payment"}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <CheckBox
                        {...{
                          control,

                          name: fieldNames.isOnlineActive,
                          register,
                          placeholder: "isOnlineActive",
                          label: "isOnlineActive",
                          errors,
                        }}
                      />

                      <CheckBox
                        {...{
                          control,

                          name: fieldNames.isCardActive,
                          register,
                          placeholder: "isCardActive",
                          label: "isCardActive",
                          errors,
                        }}
                      />

                      <CheckBox
                        {...{
                          control,

                          name: fieldNames.isCashActive,
                          register,
                          placeholder: "isCashActive",
                          label: "isCashActive",
                          errors,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </div> */}

						<div className="row mt-4">
							<Col md="12 border ">
								<div className="card">
									<div className="p-4">
										<h4 className="card-title">{"Select data"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<LanguageSelector
												{...{
													control,
													name: fieldNames.defaultLanguage,
													register,
													placeholder: "defaultLanguage",
													label: "defaultLanguage",
													errors,
													...(!URL_ID && { defaultValue: "en" }),
												}}
											/>

											<LanguageSelector
												isMulti
												selectAll={!URL_ID}
												{...{
													control,
													name: fieldNames.languages,
													register,
													placeholder: "languages",
													label: "languages",
													errors,
												}}
											/>

											{/* <FieldSelect
												{...{
													// Controller,
													name: fieldNames.invoice,
													register,
													label: "invoice",
													errors,
													control,
													options: INVOICE_DATA,
												}}
											/> */}

											{/* <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.foodInvoice,
                          register,
                          label: "foodInvoice",
                          errors,
                          control,
                          options: INVOICE_DATA,
                        }}
                      /> */}

											{/* <FieldSelect
                        {...{ 
                          // Controller,
                          name: fieldNames.drink,
                          register,
                          label: "drink",
                          errors,
                          control,
                          options: DRINK_DATA,
                        }}
                      /> */}

											{/* <BranchSelector
                        {...{
                          // Controller,
                          name: fieldNames.branch,
                          register,
                          label: "branch",
                          errors,
                          control,
                        }}
                      /> */}
											<RegionSelector
												{...{
													// Controller,
													name: fieldNames.region,
													register,
													label: "region",
													errors,
													control,
												}}
											/>

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "Status",
													errors,
													control,
													options: STATUSES,
												}}
											/>
										</div>
									</div>
								</div>
							</Col>
						</div>

						<div className="row mt-4">
							<Col md="12  p-3">
								<div className="card">
									{/* <div className="p-4">
                    <h4 className="card-title">{t("except")}</h4>
                  </div> */}

									<Row className="">
										<Col lg={6}>
											<h5 className="text-info">{t("exceptCategories")}</h5>
										</Col>
										{/* <Col lg={6} className="d-flex justify-content-end">
                      <Button onClick={addNewExceptCategory} className={"d-flex align-items-center"} size="sm">
                        <div className={"gallery-add-title"}>
                          {t("add")}
                        </div>
                        <div className={"ml-1"}>
                          +
                        </div>
                      </Button>
                    </Col> */}
									</Row>

									<div className="mt-0">
										<div className="col-12">
											<div className="basic-form"></div>
											<Controller
												name={fieldNames.exceptCategories}
												control={control}
												render={({ field: { onChange, value } }) => {
													console.log({ value });

													return Children.toArray(
														(value || [{ ...defaultDataExceptCategory }]).map((locale, index) => {
															const values2 = getValues();

															function changeHandle(param) {
																console.log({ param });
																let values = value ? [...value] : [{ ...defaultData }];
																values[index][param.name] = param.value;
																onChange(values);
															}

															const removeClick = (index) => {
																let values = getValues();
																if (!values[fieldNames.exceptCategories]) return;
																const newValues = values[fieldNames.exceptCategories].filter((param, i) => i !== index);
																setValue(fieldNames.exceptCategories, newValues);
															};

															return (
																<Row className="user-row shadow py-3 mt-4" key={value?.email || index}>
																	{true ? (
																		<>
																			<Col lg={8}>
																				<h7>
																					{t("exceptCategories")}({index + 1})
																				</h7>
																			</Col>
																			<Col lg={4}>
																				<div>
																					<Button
																						color="warning"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																			</Col>
																			{/* <Col lg={12}>
                                        <hr style={{ marginBottom: 0 }} />
                                      </Col> */}
																		</>
																	) : (
																		""
																	)}

																	<Col xs={12} className="mt-1">
																		<CategorySelector
																			isMulti
																			{...{
																				name: `exceptCategories.[${index}].categories`,
																				register,
																				label: "categories",
																				error: errors.exceptCategories?.[index].categories,
																				control,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `exceptCategories.[${index}].startTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "startTime",
																				error: errors.exceptCategories?.[index].startTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																			disables={{
																				now: true,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `exceptCategories.[${index}].endTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "endTime",
																				error: errors.exceptCategories?.[index].endTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																			disables={{
																				now: true,
																			}}
																		/>
																	</Col>
																</Row>
															);
														})
													);
												}}
											/>
											<Col xs={12} className="mt-3">
												<Button onClick={addNewExceptCategory} className={"flex text-center w-100"} size="sm">
													<div className={"text-center"}>{t("add")}</div>
												</Button>
											</Col>
										</div>
									</div>

									{/* <div className="card-body">
                    <div className="basic-form">


                      <CategorySelector
                        isMulti

                        {...{
                          name: fieldNames.exceptCategories,
                          register,
                          label: "category",
                          errors,
                          control,
                        }}
                      />

                      <ProductSelector

                        isMulti
                        {...{
                          Controller,
                          name: fieldNames.exceptProducts,
                          register,
                          label: "product",
                          errors,
                          control,
                        }}
                      />




                    </div>
                  </div> */}
								</div>
							</Col>
						</div>

						<div className="row mt-4">
							<Col md="12">
								<div className="card">
									<Row className="">
										<Col lg={6}>
											<h5 className="text-info">{t("exceptProducts")}</h5>
										</Col>
										{/* <Col lg={6} className="d-flex justify-content-end">
                      						<Button onClick={addNewExceptProduct} className={"d-flex align-items-center"} size="sm">
                        						<div className={"gallery-add-title"}>
                          						{t("add")}
                        						</div>
                        						<div className={"ml-1"}>
                          						+
                        						</div>
                      						</Button>
                    						</Col> */}
									</Row>

									<div className="mt-0">
										<div className="col-12">
											<div className="basic-form"></div>
											<Controller
												name={fieldNames.exceptProducts}
												control={control}
												render={({ field: { onChange, value } }) => {
													console.log({ value });

													return Children.toArray(
														(value || [{ ...defaultDataExceptCategory }]).map((locale, index) => {
															const removeClick = (index) => {
																let values = getValues();
																if (!values[fieldNames.exceptProducts]) return;
																const newValues = values[fieldNames.exceptProducts].filter((param, i) => i !== index);
																setValue(fieldNames.exceptProducts, newValues);
															};

															return (
																<Row className="user-row shadow py-3 mt-4" key={value?.email || index}>
																	{true ? (
																		<>
																			<Col lg={8}>
																				<h7>
																					{t("exceptProducts")}({index + 1})
																				</h7>
																			</Col>
																			<Col lg={4}>
																				<div>
																					<Button
																						color="warning"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																			</Col>
																			{/* <Col lg={12}>
                                        <hr style={{ marginBottom: 0 }} />
                                      </Col> */}
																		</>
																	) : (
																		""
																	)}
																	<Col xs={12} className="mt-1 ">
																		<ProductSelector
																			isMulti
																			{...{
																				name: `exceptProducts.[${index}].products`,
																				register,
																				label: "products",
																				error: errors.exceptProducts?.[index].products,
																				control,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `exceptProducts.[${index}].startTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "startTime",
																				error: errors.exceptProducts?.[index].startTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																			disables={{
																				now: true,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `exceptProducts.[${index}].endTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "endTime",
																				error: errors.exceptProducts?.[index].endTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																			disables={{
																				now: true,
																			}}
																		/>
																	</Col>
																</Row>
															);
														})
													);
												}}
											/>

											<Col xs={12} className="mt-3">
												<Button onClick={addNewExceptProduct} className={"flex text-center w-100"} size="sm">
													<div className={"text-center"}>{t("add")}</div>
												</Button>
											</Col>
										</div>
									</div>
								</div>
							</Col>
						</div>

						<div className="row mt-4">
							<Col md="12">
								<div className="card">
									<Row className="">
										<Col lg={6}>
											<h5 className="text-info">{t("includeProducts")}</h5>
										</Col>
										{/* <Col lg={6} className="d-flex justify-content-end">
                      						<Button onClick={addNewExceptProduct} className={"d-flex align-items-center"} size="sm">
                        						<div className={"gallery-add-title"}>
                          						{t("add")}
                        						</div>
                        						<div className={"ml-1"}>
                          						+
                        						</div>
                      						</Button>
                    						</Col> */}
									</Row>

									<div className="mt-0">
										<div className="col-12">
											<div className="basic-form"></div>
											<Controller
												name={fieldNames.includeProducts}
												control={control}
												render={({ field: { onChange, value } }) => {
													console.log({ value });

													return Children.toArray(
														(value || [{ ...defaultDataExceptCategory }]).map((locale, index) => {
															const removeClick = (index) => {
																let values = getValues();
																if (!values[fieldNames.includeProducts]) return;
																const newValues = values[fieldNames.includeProducts].filter((param, i) => i !== index);
																setValue(fieldNames.includeProducts, newValues);
															};

															return (
																<Row className="user-row shadow py-3 mt-4" key={value?.email || index}>
																	{true ? (
																		<>
																			<Col lg={8}>
																				<h7>
																					{t("includeProducts")}({index + 1})
																				</h7>
																			</Col>
																			<Col lg={4}>
																				<div>
																					<Button
																						color="warning"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																			</Col>
																			{/* <Col lg={12}>
                                        <hr style={{ marginBottom: 0 }} />
                                      </Col> */}
																		</>
																	) : (
																		""
																	)}
																	<Col xs={12} className="mt-1 ">
																		<ProductSelector
																			isMulti
																			{...{
																				name: `includeProducts.[${index}].products`,
																				register,
																				label: "products",
																				error: errors.includeProducts?.[index].products,
																				control,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `includeProducts.[${index}].startTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "startTime",
																				error: errors.includeProducts?.[index].startTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																			disables={{
																				now: true,
																			}}
																		/>

																		<FieldDate
																			{...{
																				control,
																				name: `includeProducts.[${index}].endTime`,
																				register,
																				placeholder: " 11:20 am",
																				label: "endTime",
																				error: errors.includeProducts?.[index].endTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																			disables={{
																				now: true,
																			}}
																		/>
																	</Col>
																</Row>
															);
														})
													);
												}}
											/>

											<Col xs={12} className="mt-3">
												<Button onClick={addIncludeProducts} className={"flex text-center w-100"} size="sm">
													<div className={"text-center"}>{t("add")}</div>
												</Button>
											</Col>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</FormProvider>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
////     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
