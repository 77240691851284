import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Breadcrumb from 'react-bootstrap/Breadcrumb';

const PageTitle = ({ breadcrumbs, title, button, buttons }) => {
  // let path = window.location.pathname.split("/");

  return (
    <div className="row page-titles mx-0 pt-3 pb-3">
      <div className="col-sm-6 p-md-0">
        <div className=" d-flex ">

          {/* <Breadcrumb>
            {breadcrumbs.map(({ title, link, active }) => {
              return <Breadcrumb.Item href={link} active={active}>{title}</Breadcrumb.Item>
            })}

          </Breadcrumb> */}

          <div className="table-title-wrapper ">
            <h3 className="card-title">{title}</h3>
            {/* <h4 className="card-title">{title}({count}/{showing})</h4> */}
          </div>

        </div>
      </div>

      <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex flex-wrap">

        {
          buttons ?
            buttons?.map((x) => {

              return x && (

                // x?.link ? (
                <Link to={x.link || "#"} key={x.title} target={x?.target} className="mt-2">
                  <Button
                    // block
                    type={x?.type || "success"}
                    // htmlType="submit"
                    className={[`btn btn-sm mr-2`, x?.className].join(" ")}
                    form={"form-container"}
                    onClick={x?.onClick || null}
                  >
                    {x.title}
                  </Button>
                </Link>
                // ) : (
                //   x && (
                //     <Button
                //       className={`btn btn-success btn-sm mr-2`}
                //       type={x?.type || "success"}
                //       form={"form-container"}
                //       onClick={x?.onClick || null}
                //     >
                //       {x.title}
                //     </Button>
                //   )
                // )

                // <Link to={x.link}>
                //   <Button
                //     className={`btn btn-success btn-sm mr-2`}
                //     type="success"
                //     form={"form-container"}
                //   >
                //     {x.title}
                //   </Button>
                // </Link>
              )
            })
            :
            button?.link ? (
              <Link to={button.link} className="mt-2">
                <Button
                  // block
                  type="success"
                  // htmlType="submit"
                  className={[`btn btn-success btn-sm mr-2 mt-2`, button?.className].join(" ")}
                  form={"form-container"}
                >
                  {button.title}
                </Button>
              </Link>
            ) : (

              button && (
                <Button
                  className={[`btn btn-success btn-sm mr-2 mt-2`, button?.className].join(" ")}
                  type="success"
                  form={"form-container"}
                  onClick={button?.onClick || null}
                >
                  {button.title}
                </Button>
              )
            )}
      </div>
    </div>
  );
};

export default PageTitle;
