import TableAction from "components/TableAction";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
import TableStatus from "components/TableStatus";
import CheckedComponent from "components/CheckedComponent";
import { convertTimeTo24HourFormat } from "utils/convertor";

export const tableData = ({ onClick, newRoute, ...props }) => [
	{
		header: "name",
		Component: ({ param }) => param.name,
	},
	// {
	//   header: "branches",
	//   Component: ({ param }) => param.branches?.length,
	// },
	{
		header: "menuCurrency",
		Component: ({ param }) => param.menuCurrency,
	},
	{
		header: "currencyMultiplier",
		Component: ({ param }) => param.currencyMultiplier,
	},
	{
		header: "menus",
		Component: ({ param }) => param.menus?.length,
	},
	{
		header: "code",
		Component: ({ param }) => param.code,
	},
	{
		header: "averageDeliveryTime",
		Component: ({ param }) => param.averageDeliveryTime,
	},
	{
		header: "startTime",
		Component: ({ param }) => convertTimeTo24HourFormat(param.startTime) || "-",
	},
	{
		header: "endTime",
		Component: ({ param }) => convertTimeTo24HourFormat(param.endTime) || "-",
	},
	{
		header: "currentNotWorkingReason",
		Component: ({ param }) => param.currentNotWorkingReason || "-",
	},
	{
		header: "createdAt",
		Component: ({ param }) => isoToDate(param.createdAt),
	},
	{
		header: "isOnlineActive",
		Component: ({ param }) => <CheckedComponent checked={param.isOnlineActive} />,
	},
	{
		header: "isCardActive",
		Component: ({ param }) => <CheckedComponent checked={param.isCardActive} />,
	},
	{
		header: "isCashActive",
		Component: ({ param }) => <CheckedComponent checked={param.isCashActive} />,
	},
	{
		header: "Status",
		Component: ({ param }) => <TableStatus {...{ param, onClick, slice: { second: 5 }, disabled: props?.edit?.disabled }} />,
	},
	{
		header: "Actions",
		Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
	},
];
