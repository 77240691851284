import FieldDate from "components/FieldDate";
import moment from "moment";
import routes from "operations/routing/routes";
import { Children, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import { FINANCE_DATE, FINANCE_DATE_OPTIONS } from "./enum";

const FinanceDatingComponent = ({ QueryString, baseUrl }) => {
	const { t, i18n } = useTranslation();
	let { years } = useParams();
	years = years || FINANCE_DATE[1];
	const from = QueryString?.from;
	const to = QueryString?.to;

	let d = moment(from);
	let currentMonth = d.month();
	if (+d.year() !== +years) {
		d.year(years);
		// d.month(currentMonth);
	}

	const location = useLocation();
	const navigate = useNavigate();

	const changeUrl = (params) => ({
		pathname: location.pathname,
		search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
	});

	const navigateUrl = (obj) => navigate(changeUrl(obj));
	const monthsOptions = useMemo(() => {
		moment.locale(i18n.language);
		return moment.months()?.map((x, i) => ({ label: x, value: i + 1 }));
	}, [i18n.language]);
	const selectedMonth = monthsOptions[d.month()];

	console.log({ monthsOptions, from, to });
	// console.log({ monthsOptions, from,to, currentMonth, month: d.month(), year: d.year() });
	return (
		<div className="d-flex gap-2">
			<div>
				<label className="mb-1">{t("years")}</label>
				<Dropdown variant="primary" className=" ">
					<Dropdown.Toggle id="dropdown-basic" className=" btn outline filter-sort mr-3">
						<span>{years}</span>
					</Dropdown.Toggle>

					<Dropdown.Menu className="dropDown-menu-custom">
						<Dropdown.Header className="text-capitalize" header>
							{t("years")}
						</Dropdown.Header>
						{Children.toArray(
							FINANCE_DATE_OPTIONS?.map((x) => (
								<Dropdown.Item
									key={x.label + "_x"}
									active={x.value === years}
									onClick={() =>
										navigate({
											pathname: baseUrl + "/" + x.value,
										})
									}
									className="dropDown-item-custom text-capitalize">
									{t(x?.label)}
								</Dropdown.Item>
							))
						)}
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<div className="mb-2">
				<div>
					<label className="mb-1">{t("month")}</label>
					<Dropdown variant="primary" className=" ">
						<Dropdown.Toggle id="dropdown-basic" className=" btn outline filter-sort mr-3">
							<span>{selectedMonth?.label}</span>
						</Dropdown.Toggle>

						<Dropdown.Menu className="dropDown-menu-custom">
							<Dropdown.Header className="text-capitalize" header>
								{t("month")}
							</Dropdown.Header>
							{Children.toArray(
								monthsOptions?.map((x) => (
									<Dropdown.Item
										key={x.label + "_x"}
										active={x.value === selectedMonth?.value}
										onClick={() => {
											let newD = moment()
												.year(years)
												.month(x.value - 1);
											const from = new Date(newD.startOf("month"));
											const to = new Date(newD.endOf("month"));
											navigateUrl({
												from,
												to,
												page: 1,
											});
										}}
										className="dropDown-item-custom text-capitalize">
										{t(x?.label)}
									</Dropdown.Item>
								))
							)}
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};

export default FinanceDatingComponent;
