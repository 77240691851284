const baseStrings = {
  translation: {
    name: "Ad",
    dashboard: "Dashboard",
    order: "Sipariş",
    product: "Ürün",
    category: "Kategori",
    user: "Kullanıcı",
    branch: "Bayi",
    hotel: "Otel",
    gallery: "Galleri",
    language: "Dil",
    setting: "Ayarlar",
    allStatus: "Tün Durumlar",
    delete: "Sil",
    add: "Ekle",
    edit: "Düzenle",
    from: "İle",
    to: "İle",
    submit: "Onayla",
    orderLists: "Sipariş Listesi",
    payment: "Ödeme",
    paymentType: "Ödeme Yöntemi",
    roomNumber: "Oda Numarası",
    products: "Ürünler",
    totalPrice: "Toplam Tutar",
    status: "Durum",
    note: "Not",
    createdAt: "Oluşturulma Tarihi",
    action: "İşlem",
    search: "Ara",
    newest: "En Yeni",
    image: "Resim",
    price: "Fiyat",
    translations: "Çeviriler",
    stock: "Stok",
    integrationCode: "Entegrasyon Kodu",
    isFastDelivery: "Hızlı Teslimat",
    isNewProduct: "Yeni Ürün",
    listOrderNumber: "Listeleme Sırası",
    addProduct: "Ürün Ekle",
    active: "Aktif",
    inactive: "Pasif",
    parent: "Ana",
    categoryType: "Kategory Türü",
    isNewCategory: "Yeni Kategori",
    email: "Email",
    emailNotification: "Email Bilgilendirme",
    phoneNotification: "SMS Bilgilendirme",
    role: "Yetki",
    commercialTitle: "Ticari Unvan",
    currentNotWorkingReason: "Kapanış Metni",
    startDate: "Açılış Saati",
    endDate: "Kapanış Saati",
    address: "Adres",
    panelName: "Panel Adı",
    taxAdministration: "Vergi Dairesi",
    taxNo: "Vergi Numarası",
    code: "Kod",
    isSystemWorking: "Sistem Aktif",
    systemStartTime: "Sistem Açiliş Saati",
    systemEndTime: "Sistem Kapanış Saati",
    qrCode: "QR Code",
    users: "Kullanıcılar",
    menues: "Menuler",
    menue: "Menuler",
    disable: "Pasif",
    commission: "Komisyon",
    defaultLanguage: "Açılış Dili",
    drink: "İçecek",
    drinkInvoice: "İçecek Fatura",
    foodInvoice: "Yiyecek Fatura",
    invoice: "Fatura",
    formal: "Resmi",
    inFormal: "Gayri Resmi",
    orderLimit: "Sipariş Limiti",
    phoneNumber: "Telefon Numarası",
    roomCount: "Oda Sayısı",
    serviceCharge: "Service Charge",
    allOrders: "Toplam Siparişler",
    dailyOrders: "Günlük Siparişler",
    monthlyOrders: "Aylık Siparişler",
    total: "Toplam",
    finance: "Finans",
    totalAmount: "Toplam Tutar",
    commission: "Komisyon",
    all: "Hepsi",
    multiplePrice: "Toplu Fiyat",
    updateAuthor: "{{author}} Güncelle",
    addAuthor: "{{author}} Ekle",
    editAuthor: "{{author}} Düzenle",
    listAuthor: "{{author}} listesi",
    listsAuthor: "{{author}} Listesi",
    selectSearch: "Arama Yapılabilir",
    orderCount: "Sipariş Sayısı",
    totalCommission: "Toplam Komisyon",
    actions: "İşlemler",
    startTime: "Açılış Saati",
    endTime: "Kapanış Saati",
    isOrderLimit: "Sipariş Limiti",
    menus: "Menus",
    delivery: "Teslimat",
    deliveries: "Sürücü",
    places: "Yerler",
    orderNumber: "Sipariş Numarası",
    operation: "İşlem",
    operationSide: "İşlem Turu",
    operationUnit: "İşlem Değeri",
    exportExcel: "Excel Olarak İndir",
    refund: "İade Alındı",
    canceled: "İptal Edildi",
    paymentSuccess: "Ödeme Başarılı",
    paymentFailed: "Ödeme Başarısız",
    waiting: "Bekliyor",
    sended: "Gönderildi",
    preparing: "Hazırlanıyor",
    delivered: "Teslim Edildi",
    onWay: "Yolda",
    totalOrders: "Toplam Sipariş",
    card: "Kart",
    online: "Online",
    cash: "Nakit",
    limitAuthor: "Adet {{author}}",
    oldest: "En Eski",
    note: "Not",
    isOnlineActive: "Online Ödeme",
    isCardActive: "Kapıda Kredi Kartı",
    isCashActive: "Nakit Ödeme",
    publish: "Kaydet",
    selectDate: "Tarih Seç",
    other: "Diğer",
    exceptCategories: "İstenmeyen Kategoriler",
    statics: "İstatistik",
    orders: "Sipariş",
    detailedOrders: "Detaylı Sipariş",
    priceWithoutKdv: "KDV Hariç Fiyat",
    alcohol: "Alkol",
    coldDrink: "Soğuk İçecek",
    ibanName: "Hesap Adı",
    nameSurname: "Misafir Adı",
    buyPrice: "Alış Fiyatı",
    productHour: "Ürün Saati",
    hour: "Saat",
    updatedAt: "Güncellenme Tarihi",
    firstName: "İsim",
    lastName: "Soyisim",
    activities: "Aktivite",
    errors: {
      "is-require": "{{author}} zorunludur",
      "is-not-valid": "{{author}} geçerli değil",
      "please-enter-your": "lütfen {{author}} girin",
      "please-enter": "lütfen {{author}} girin",
      "please-select-your": "lütfen {{author}} girin",
      "must-be": "{{author}} {{kind}} olmalıdır",
      min: "The lowest character is {{author}}",
      max: "The maximum number of characters is {{author}}",
    },
    totalPriceTry: "Toplan Tutar TL",
    totalPriceEuro: "Toplan Tutar Euro",
    paymentCurrency: "Ödeme Şekli",
    deliveryName: "Kurye Adı",
    guestName: "Misafir Adı",
    regionName: "Bölge",
    region: "Bölge",
    assignedAt: "Atanma Tarihi",
    assignmentDuration: "Atanma Süresi",
    onWayAt: "Yolda Tarihi",
    rideDuration: "Sürüş Süresi",
    deliveredAt: "Teslim Tarihi",
    deliveryDuration: "Toplam Deneyim Süresi",
    productsBuyed: "Dışarıdan Alınan Ürünler",
    deliveryReport: "Sipariş Takibi",
    served: "İkram",
    duplicated: "Mükerrer",
    title: "Başlık",
    solution: "Çözülmesi Hedeflenen Sorun",
    solutions: "Çözülmesi Hedeflenen Sorun",
    text: "Açıklama",
    dueDate: "Teslim Tarihi",
    piority: "Öncelik",
    low: "Düşük",
    normal: "Normal",
    high: "Yüksek",
    priority: "Öncelik",
    "static-hotels": "QR Okutma Raporu (Otel)",
    scanCount: "tarama Sayısı",
    hotelName: "Otel adı",
    productName: "urun adi",
    exceptProducts: "Except products",
    includeProducts: "Include products",
    "cancel-note": "Notu iptal et",
    totalOrderPrice: "Toplam Sipariş Tutarı",
    setupCost: "Kurulum Maliyeti",
    contactUs: "Contact Us",
    paymentMethod: "Ödeme Yöntemi",
    financeCash: " Aylık Nakit",
    invoice: "Faturalı",
    giftCard: "Hediye Kartı",
    "download-all-room-numbers": "Download all room numbers",
    "download-urls": "URL indir",
    userName: "Kullanıcı Adı",
    clock: "Saat",
    date: "Tarih",
    totalScan: "Toplam Okutma",
    deliveryReport: "Teslimat Raporu",
    staticsDetailed: "QR Okutma Raporu (Detaylı)",
    "static-rooms": "QR Okutma Raporu (Oda)",
    month: "Ay",
    years: "Yıllar",
    isNetworkDry: "NetworkDry Ürünü",
    serviceChargeIncludedPrice: "Hizmet bedeli dahil fiyat",
  },
};

export const tr = baseStrings;
