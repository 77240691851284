import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, PARENT_API_NAME, ROUTE_NAME, PARENT_ROUTE_NAME, PARENT_COMPONENT_NAMES } from "../enum";
import { tableData } from "./tableInfo";

const List = () => {
  const [checkList, setCheckList] = useState();
  // ---------------------------------------------- new route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const newParentRoute = useMemo(() => getDataInObjectDepth(PARENT_ROUTE_NAME, routes), []);
  // ---------------------------------------------- Constance
  const QueryString = useQueryString({ sort: "desc" });
  const { page, limit } = QueryString;
  const dispatch = useDispatch();
  let { id } = useParams();
  const button = { title: `Add ${COMPONENT_NAMES[0]}`, link: newRoute.base + newRoute.add + "/" + id };


  // ---------------------------------------------- fetching functions
  const getting = async () =>
    await axiosClient().get(api[API_NAME].get + "/" + id, {
      params: { ...QueryString },
    });
  const changeStatus = async (params) => {
    // console.log(params, "changeStatus");
    await axiosClient().patch(api[API_NAME].changeStatus, params);
  };
  const deleting = async (params) => {
    await axiosClient().delete(api[API_NAME].delete + "/" + id + "/item/" + params?.id);
  }
  const deletingAll = async () => {
    await axiosClient().delete(api[API_NAME].delete + "/all/" + id);
  }
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: getting,
    params: { ...QueryString },
  });

  // ---------------------------------------------- mutation Change Status
  const onSuccessDeleteAllMutation = () => {
    toastify.success({ title: "success" });
  };

  const deleteAllMutation = useMutationCustom({
    name: `${API_NAME}_deleteAll`,
    url: deletingAll,
    invalidQuery: `${API_NAME}_get`,
    onSuccess: onSuccessDeleteAllMutation,
  });

  // ---------------------------------------------- mutation Change Status
  const onSuccessDeleteMutation = () => {
    toastify.success({ title: "success" });
  };

  const deleteMutation = useMutationCustom({
    name: `${API_NAME}_delete`,
    url: deleting,
    invalidQuery: `${API_NAME}_get`,
    onSuccess: onSuccessDeleteMutation,
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessChangeStatusMutation = () => {
    toastify.success({ title: "success" });
  };

  const changeStatusMutation = useMutationCustom({
    name: `${API_NAME}_changeStatus`,
    url: changeStatus,
    invalidQuery: `${API_NAME}_get`,
    onSuccess: onSuccessChangeStatusMutation,
  });
  // ---------------------------------------------- logs
  console.log({ data, error, isError, isLoading });
  // ---------------------------------------------- action click table

  const actionsOperation = ({ data, status }) => {
    swal({
      title: "Are you sure?",
      text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willBe) => {
      if (willBe) {
        if (status.label === "delete") deleteMutation.mutate({ id: data });
        else if (status.label === "deleteAll") deleteAllMutation.mutate();
        else changeStatusMutation.mutate({ ids: data, isActive: status.value });

      }
    });
  };



  const clickAction = ({ data, status, ...props }) => {
    const newData = data?.map((x) => x.id);
    actionsOperation({ data: newData, status, ...props });
  };

  const handleAction = (status) => {
    const data = Object.keys(checkList);
    console.log({ data, status });
    actionsOperation({ data, status: status });
  };
  // ---------------------------------------------- breadcrumbs
  const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: PARENT_COMPONENT_NAMES[0], link: newParentRoute.base }, { title: COMPONENT_NAMES[0] }, { title: "List" }];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, []);

  const tableOptions = tableData({ onClick: clickAction, newRoute, id });
  const buttons = [
    { title: `Delete all`, onClick: () => clickAction({ status: { label: "deleteAll" } }), type: "warning" },
    { title: `Add ${COMPONENT_NAMES[0]}`, link: newRoute.base + newRoute.add + "/" + id },
    { title: `copyMenu`, link: newRoute.base + newRoute.copy + "/" + id },
  ];
  //  ----------------------------------------- LOGS ----------------------------------------- //
  console.log({ checkList, data }, "checkList");

  return (
    <div>
      <PageTitle title={`${COMPONENT_NAMES[0]} lists`} breadcrumbs={breadcrumbs} buttons={buttons} />
      <TableComponent
        {...{
          QueryString,
          data: data?.items,
          tableOptions,
          loading: isLoading,
          // search: {
          //   placeholder: `Search ${COMPONENT_NAMES[0]}`,
          //   defaultValue: QueryString.search_text,
          // },
          title: `${COMPONENT_NAMES[1]}`,
          button: button,
          count: data.total,
          pagination: {
            activePage: page,
            pages: Math.ceil(data.total / limit),
            limited: 2,
          },
          checkbox: {
            state: checkList,
            setState: setCheckList,
          },
          actions: {
            onClick: handleAction,
          },
          status: STATUSES,
          disables: {
            all: true
          }
        }}
      />
    </div>
  );
};

export default List;
