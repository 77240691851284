import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";
import BranchSelector, { convertBranchesData } from "containers/Branch/BranchSelector";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION, DAYS_OBJECT } from "../enum";
import { CURRENCY_DATA, CURRENCY_OBJECT, DRINK_DATA, STATUSES_OBJECT, ROLES, STATUSES } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import moment from "moment";
import FieldDate from "components/FieldDate";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import MenuSelector, { convertMenuData } from "../../Menus/MenuSelector";
import { useSelector } from "react-redux";
import { convertTimeTo24HourFormat } from "utils/convertor";
import ProductSelector, { convertProductData } from "containers/Product/ProductSelector";

const Add = () => {
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	const { t } = useTranslation();
	const location = useLocation();

	// const adminState = useSelector((state) => state.admin);
	// const validPermission = adminState?.data?.permissions === true
	// const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION]
	// console.log({ permissions });
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;

	const typeOptions = [
		{ label: "User", value: "USER" },
		{ label: "Admin", value: "ADMIN" },
	];

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	const navigation = useNavigate();
	let { id } = useParams();
	const URL_ID = id ? id : "";
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		try {
			// console.log("resData ===>", { data });

			let { payment, ...params } = data;
			let date = "2023-03-13";
			// console.log("resData ===>", date + " " + params?.startTime);
			// console.log("resData ===>", convertTimeTo24HourFormat("04:32 PM"));
			// console.log("resData ===>", new Date("2023-03-13 02:30 PM"));
			// return;
			const resData = {
				...params,
				isActive: STATUSES.find((item) => item.value === params?.isActive),
				startTime: params?.startTime && new Date(date + " " + convertTimeTo24HourFormat(params?.startTime)),
				endTime: params?.endTime && new Date(date + " " + convertTimeTo24HourFormat(params?.endTime)),
				// branches: convertBranchesData(params.branches),
				menuCurrency: CURRENCY_OBJECT[params?.menuCurrency],
				menus: params?.menus?.map((x) => ({
					menu: convertMenuData(x.menu),
					days: x?.days?.map((y) => DAYS_OBJECT[y]),
					startTime: x?.startTime && new Date(date + " " + convertTimeTo24HourFormat(x?.startTime)),
					endTime: x?.endTime && new Date(date + " " + convertTimeTo24HourFormat(x?.endTime)),
				})),
				campaign: {
					product: convertProductData(params?.campaign?.product),
					startTime: params?.campaign?.startTime && new Date(date + " " + convertTimeTo24HourFormat(params?.campaign?.startTime)),
					endTime: params?.campaign?.endTime && new Date(date + " " + convertTimeTo24HourFormat(params?.campaign?.endTime)),
					isActive: STATUSES_OBJECT[params?.campaign?.isActive],
				},
				isOnlineActive: !!payment?.isOnlineActive,
				isCardActive: !!payment?.isCardActive,
				isCashActive: !!payment?.isCashActive,
			};
			console.log("resData ===>", { resData });
			// handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image });
			reset(resData);
		} catch (error) {
			console.log("* * * error * * * ", { error });
		}
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		console.log({ values: getValues() });
		const { endTime, startTime, menus, branches, isOnlineActive, isCardActive, isCashActive, ...values } = getValues();
		const requestData = {
			...values,

			currencyMultiplier: values?.currencyMultiplier >= 0 ? +values.currencyMultiplier : undefined,
			isActive: values.isActive ? values.isActive.value : "1",
			startTime: moment(startTime).format("hh:mm A"),
			endTime: moment(endTime).format("hh:mm A"),
			menuCurrency: values?.menuCurrency?.value,
			menus: menus?.map((x) => ({
				menu: x?.menu?.value,
				days: x?.days?.map((x) => x.value),
				startTime: x?.startTime && moment(x?.startTime).format("hh:mm A"),
				endTime: x?.endTime && moment(x?.endTime).format("hh:mm A"),
			})),
			payment: {
				isOnlineActive: !!isOnlineActive,
				isCardActive: !!isCardActive,
				isCashActive: !!isCashActive,
			},
			campaign: {
				product: values?.campaign?.product?.value,
				startTime: values?.campaign?.startTime && moment(values?.campaign?.startTime).format("hh:mm A"),
				endTime: values?.campaign?.endTime && moment(values?.campaign?.endTime).format("hh:mm A"),
				isActive: values?.campaign?.isActive?.value,
			},
			// branches: branches?.map((x) => x?.value)
			// image: values.image._id,
		};
		// image: values.image._id,
		console.log("requestData ===>", { requestData });
		console.log("values", values);
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.name}` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const defaultData = { user_name: "" };

	const addNewUser = () => {
		const values = getValues();
		const newData = values[fieldNames.users] ? [...values[fieldNames.users], { ...defaultData }] : [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.users, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};
	const addNewMenu = () => {
		const values = getValues();
		const newData = values[fieldNames.menus] ? [...values[fieldNames.menus], { ...defaultData }] : [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.menus, newData);
	};
	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													control,
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													control,
													name: fieldNames.code,
													register,
													placeholder: "code",
													label: "code",
													errors,
												}}
											/>

											{/* <FieldText
                        require
                        {...{
                          control,
                          name: fieldNames.telegramChatId,
                          register,
                          placeholder: "telegramChatId",
                          label: "telegramChatId",
                          errors,
                        }}
                      /> */}

											<FieldText
												require
												{...{
													control,
													name: fieldNames.averageDeliveryTime,
													register,
													placeholder: "averageDeliveryTime",
													label: "averageDeliveryTime",
													errors,
												}}
											/>

											{/* <BranchSelector
                        isMulti
                        {...{
                          // Controller,
                          name: fieldNames.branches,
                          register,
                          label: "branch",
                          errors,
                          control,
                        }}
                      /> */}

											<FieldDate
												require
												{...{
													control,
													name: fieldNames.startTime,
													register,
													placeholder: " 11:20 am",
													label: "startTime",
													errors,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="HH:mm"
												showTimeInput={false}
											/>

											<FieldDate
												require
												{...{
													control,
													name: fieldNames.endTime,
													register,
													placeholder: "endTime",
													label: "endTime ",
													errors,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="HH:mm"
												showTimeInput={false}
											/>

											<FieldText
												require
												{...{
													control,

													name: fieldNames.currentNotWorkingReason,
													register,
													placeholder: "currentNotWorkingReason",
													label: "currentNotWorkingReason",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													control,

													name: fieldNames.currencyMultiplier,
													register,
													placeholder: "currencyMultiplier",
													label: "currencyMultiplier",
													errors,
													type: "number",
												}}
											/>

											<FieldSelect
												require
												{...{
													Controller,
													name: fieldNames.menuCurrency,
													register,
													label: "menuCurrency",
													errors,
													control,
													options: CURRENCY_DATA,
												}}
											/>

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "Status",
													errors,
													control,
													options: STATUSES,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 border p-4 mt-4">
							<div className="">
								<Row className="b-1">
									<Col lg={6}>
										<h2 className="">Menu</h2>
									</Col>
									<Col lg={6} className="d-flex justify-content-end"></Col>
								</Row>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form"></div>

										<Row className="b-1">
											<Col lg={6}>
												<h2 className="">Menus</h2>
											</Col>
											<Col lg={6} className="d-flex justify-content-end">
												<Button onClick={addNewMenu} className={"d-flex align-items-center"} size="sm">
													<div className={"gallery-add-title"}>Add Menu</div>
													<div className={"ml-1"}>+</div>
												</Button>
											</Col>
										</Row>
										<div className="mt-5">
											<div className="col-12">
												<div className="basic-form"></div>
												<Controller
													name={fieldNames.menus}
													control={control}
													render={({ field: { onChange, value } }) => {
														console.log({ value });

														return Children.toArray(
															(value || [{ ...defaultData }]).map((locale, index) => {
																const removeClick = (index) => {
																	let values = getValues();
																	if (!values[fieldNames.menus]) return;
																	const newValues = values[fieldNames.menus].filter((param, i) => i !== index);
																	setValue(fieldNames.menus, newValues);
																};

																return (
																	<Row className="items-row" key={value?.translate_email || index}>
																		{index ? (
																			<>
																				<Col lg={10}>
																					<h5>Item{index + 1}</h5>
																				</Col>
																				<Col lg={2}>
																					<div>
																						<Button
																							color="warning"
																							className={"d-flex align-items-center"}
																							size="sm"
																							onClick={(e) => {
																								e.preventDefault();
																								removeClick(index);
																							}}>
																							remove
																						</Button>
																					</div>
																				</Col>
																				<Col lg={12}>
																					<hr style={{ marginBottom: 0 }} />
																				</Col>
																			</>
																		) : (
																			""
																		)}

																		<MenuSelector
																			{...{
																				Controller,
																				register,
																				name: `menus.[${index}].menu`,
																				label: "menu",
																				error: errors?.menus?.[index]?.menu,
																				control,
																			}}
																		/>

																		<FieldDate
																			require
																			{...{
																				control,
																				register,
																				name: `menus.[${index}].startTime`,
																				placeholder: " 11:20 am",
																				label: "startTime",
																				error: errors?.menus?.[index]?.startTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																		/>

																		<FieldDate
																			require
																			{...{
																				control,
																				register,
																				name: `menus.[${index}].endTime`,
																				placeholder: "endTime",
																				label: "endTime ",
																				error: errors?.menus?.[index]?.endTime,
																			}}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={5}
																			timeCaption="Time"
																			dateFormat="HH:mm"
																			showTimeInput={false}
																		/>

																		<FieldSelect
																			isMulti
																			{...{
																				Controller,
																				name: `menus.[${index}].days`,
																				register,
																				label: "days",
																				errors,
																				control,
																				options: Object.values(DAYS_OBJECT),
																			}}
																		/>
																	</Row>
																);
															})
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>

						<div className="row mt-4">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Payment"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<CheckBox
												{...{
													control,

													name: fieldNames.isOnlineActive,
													register,
													placeholder: "isOnlineActive",
													label: "isOnlineActive",
													errors,
												}}
											/>

											<CheckBox
												{...{
													control,

													name: fieldNames.isCardActive,
													register,
													placeholder: "isCardActive",
													label: "isCardActive",
													errors,
												}}
											/>

											<CheckBox
												{...{
													control,

													name: fieldNames.isCashActive,
													register,
													placeholder: "isCashActive",
													label: "isCashActive",
													errors,
												}}
											/>
										</div>
									</div>
								</div>
							</Col>
						</div>
						<div className="row mt-4">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{t("campaign")}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<ProductSelector
												{...{
													Controller,
													register,
													name: `campaign.product`,
													label: "product",
													error: errors?.campaign?.product,
													control,
												}}
											/>

											<FieldDate
												require
												{...{
													control,
													register,
													name: `campaign.startTime`,
													placeholder: " 11:20 am",
													label: "startTime",
													error: errors?.campaign?.startTime,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="HH:mm"
												showTimeInput={false}
											/>

											<FieldDate
												require
												{...{
													control,
													register,
													name: `campaign.endTime`,
													placeholder: "endTime",
													label: "endTime ",
													error: errors?.campaign?.endTime,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="HH:mm"
												showTimeInput={false}
											/>

											<FieldSelect
												{...{
													Controller,
													name: `campaign.isActive`,
													register,
													label: "status",
													errors,
													control,
													options: STATUSES,
													error: errors?.campaign?.isActive,
												}}
											/>
										</div>
									</div>
								</div>
							</Col>
						</div>
						{/* <div className="row mt-4">
              <Col md="12 border ">
                <div className="card">
                  <div className="p-4">
                    <h4 className="card-title">{"Select data"}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.drinkInvoice,
                          register,
                          label: "drinkInvoice",
                          errors,
                          control,
                          options: INVOICE_DATA,
                        }}
                      />

                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.foodInvoice,
                          register,
                          label: "foodInvoice",
                          errors,
                          control,
                          options: INVOICE_DATA,
                        }}
                      />

                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.users,
                          register,
                          label: "users",
                          errors,
                          control,
                          options: [],
                        }}
                      />


                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.drink,
                          register,
                          label: "drink",
                          errors,
                          control,
                          options: DRINK_DATA,
                        }}
                      />





                      <FieldSelect
                        {...{
                          // Controller,
                          name: fieldNames.branch,
                          register,
                          label: "branch",
                          errors,
                          control,
                          options: [],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </div> */}
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
