import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  hotels: "hotels",
  category: "category",
  product: "product",
  delivery: "delivery",
  operation: "operation",
  operationSide: "operationSide",
  operationUnit: "operationUnit",
  startTime: "startTime",
  endTime: "endTime",
};

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.hotels]: yup
      .mixed()
      // .nullable()
      .required(ERRORS.pleaseEnterYour(fieldNames.hotels))
      .label(fieldNames.hotels),
    [fieldNames.category]: yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.category)).label(fieldNames.category),
    [fieldNames.product]: yup.object().nullable(),
    [fieldNames.delivery]: yup
      .object()
      .required(ERRORS.pleaseEnterYour(fieldNames.delivery))
      .label(fieldNames.delivery),
    [fieldNames.operation]: yup.object().label(fieldNames.operation),
    [fieldNames.operationSide]: yup.mixed().label(fieldNames.operationSide),
    [fieldNames.operationUnit]: yup.string().label(fieldNames.operationUnit),

    // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
    // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
  });
