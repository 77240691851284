import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import {
	DRINK_DATA,
	DRINK_DATA_OBJECT,
	INVOICE_DATA,
	INVOICE_DATA_OBJECT,
	LANGUAGE_DATA,
	LANGUAGE_DATA_OBJECT,
	ROLES,
	STATUSES,
	STATUSES_OBJECT,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";
import CategorySelector, { convertCategoryData } from "containers/Category/CategorySelector";
import GalleryComponent from "components/gallery/GalleryComponent";
import LanguageSelector from "containers/Language/LanguageSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import FieldDate from "components/FieldDate";
import moment from "moment";
import { convertTimeTo24HourFormat } from "utils/convertor";
// "react-router-dom": "^6.3.0",
const Add = ({ copy }) => {
	const { t } = useTranslation();

	const navigation = useNavigate();
	const location = useLocation();
	let { id, years } = useParams();
	const URL_ID = id ? id : "";

	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;
	// const params = new URLSearchParams(previousQuery || {});
	// console.log({ previousQuery, aa: params.toString() });
	const typeOptions = [
		{ label: "translation", value: "translation" },
		{ label: "Admin", value: "ADMIN" },
	];
	// console.log({ copy });
	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	// const checkPreviousPage = () => {
	//   const previousPathname = history.location.state?.from.pathname;
	//   if (previousPathname) {
	//     console.log('آدرس صفحه قبلی:', previousPathname);
	//   } else {
	//     console.log('صفحه‌ی قبلی مشخص نیست.');
	//   }
	// };
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	const checkDateFormat = (date) => {
		let def = "2017-03-13";
		try {
			moment(date);
			return date;
		} catch (error) {
			return false;
		}
	};
	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID && !copy ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		// let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
		console.log({ params });

		// handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });
		const isCustomBranch = typeof params.branch === "string";
		let date = "2017-03-13";
		const resetParam = {
			...params,
			category: convertCategoryData(params.category),
			branch: isCustomBranch ? params.branch : params.branch && convertBranchData(params.branch),
			// delivery: DRINK_DATA_OBJECT[params.delivery],
			isActive: STATUSES_OBJECT[params.isActive],
			isCustomBranch,
			translations: params?.translations?.map((x) => ({
				name: x.name,
				shortDesc: x.shortDesc,
				desc: x.desc,
				language: { label: x.language, value: x.language },
				// listOrderNumber: x.listOrderNumber,
			})),
			promotionCodes: params?.promotionCodes?.map((x) => ({
				code: x.code,
				isUsed: x.isUsed,
			})),
			// meloMaxHourMinutes: checkDateFormat(params?.meloMaxHourMinutes) && new Date(moment((params?.meloMaxHourMinutes ? date + " " + params?.meloMaxHourMinutes : date)).format()),
			meloStartTime:
				params?.meloStartTime &&
				checkDateFormat(date + " " + params?.meloStartTime) &&
				new Date(moment(params?.meloStartTime ? date + " " + convertTimeTo24HourFormat(params?.meloStartTime) : date).format()),
			meloEndTime:
				params?.meloEndTime &&
				checkDateFormat(date + " " + params?.meloEndTime) &&
				new Date(moment(params?.meloEndTime ? date + " " + convertTimeTo24HourFormat(params?.meloEndTime) : date).format()),
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		};
		console.log({ resetParam, params });
		reset(resetParam);
	};

	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();
		const {
			isCashActive,
			isCardActive,
			isOnlineActive,
			translations,
			images,
			translate_language,
			isFastDelivery,
			isNewProduct,
			isNetworkDry,
			isRecommended,
			isCustomBranch,
			promotionCodes,
			...params
		} = values;

		console.log({ values, isCustomBranch });

		const reqData = {
			...params,
			images: images?.path || images,
			isActive: values.isActive?.value,
			category: values.category?.value,
			branch: isCustomBranch ? values.branch : values.branch?.value,
			varsappTelegramChatId: values?.isVarsapp ? values.varsappTelegramChatId : "",
			// delivery: values.delivery?.value,
			isFastDelivery: !!isFastDelivery,
			isNewProduct: !!isNewProduct,
			isNetworkDry: !!isNetworkDry,

			// isRecommended: !!isRecommended,
			translations: translations?.map((x) => ({
				language: x.language?.value || x.language,
				name: x.name,
				shortDesc: x.shortDesc,
				desc: x.desc,
				// listOrderNumber: x.listOrderNumber,
			})),
			branchProductName: values.branchProductName || null,
			promotionCodes: promotionCodes
				?.filter((x) => x?.code)
				?.map((x) => ({
					code: x.code,
					isUsed: !!x.isUsed,
				})),
			// meloMaxHourMinutes: values?.meloMaxHourMinutes && moment(values?.meloMaxHourMinutes).format("HH:mm"),
			meloStartTime: values?.meloStartTime && moment(values?.meloStartTime).format("hh:mm A"),
			meloEndTime: values?.meloEndTime && moment(values?.meloEndTime).format("hh:mm A"),
			// image: values.image._id,
		};
		console.log({ reqData, values });
		mutate(reqData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.name} ` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.images, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const defaultData = { translate_name: "" };

	const addNewtranslation = () => {
		const values = getValues();
		const newData = values[fieldNames.translations]
			? [...values[fieldNames.translations], { ...defaultData }]
			: [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.translations, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const isRecommendedState = watch(fieldNames.isRecommended);
	const watchIsCustomBranch = watch(fieldNames.isCustomBranch);
	const watchIsMeloProduct = watch(fieldNames.isMeloProduct);
	const watchIsVarsapp = watch(fieldNames.isVarsapp);

	const addNewPromotion = () => {
		const values = getValues();
		const newData = values[fieldNames.promotionCodes] ? [...values[fieldNames.promotionCodes], {}] : [{}, {}];
		console.log({ newData });
		setValue(fieldNames.promotionCodes, newData);
	};

	// useEffect(() => {
	//   setValue()
	// }, [watchIsCustomBranch])

	console.log("* * * product data :", { data });
	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID && !copy ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.shortDesc,
													register,
													placeholder: "shortDesc",
													label: "shortDesc",
													errors,
												}}
											/>
											{/* <FieldText
                        require
                        {...{
                          name: fieldNames.priceList,
                          register,
                          placeholder: "priceList",
                          label: "priceList",
                          errors,
                        }}
                      /> */}

											<FieldText
												require
												{...{
													name: fieldNames.desc,
													register,
													placeholder: "desc",
													label: "desc",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.stock,
													register,
													placeholder: "10",
													label: "stock",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.price,
													register,
													placeholder: "10.00",
													label: "price",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.buyPrice,
													register,
													placeholder: "10.00",
													label: "buyPrice",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.integrationCode,
													register,
													placeholder: "integrationCode",
													label: "integrationCode",
													errors,
													// type: "number"
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.listOrderNumber,
													register,
													placeholder: "listOrderNumber",
													label: "listOrderNumber",
													errors,
													type: "number",
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.branchProductName,
													register,
													placeholder: "branchProductName",
													label: "branchProductName",
													errors,
												}}
											/>
											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "status",
													errors,
													control,
													options: STATUSES,
												}}
											/>

											<CheckBox
												{...{
													control,

													name: fieldNames.isVarsapp,
													register,
													placeholder: "isVarsapp",
													label: "isVarsapp",
													errors,
												}}
											/>
											{watchIsVarsapp ? (
												<FieldText
													require
													{...{
														name: fieldNames.varsappTelegramChatId,
														register,
														placeholder: "varsappTelegramChatId",
														label: "varsappTelegramChatId",
														errors,
													}}
												/>
											) : (
												""
											)}
											<CheckBox
												{...{
													control,

													name: fieldNames.isCustomBranch,
													register,
													placeholder: "isCustomBranch",
													label: "isCustomBranch",
													errors,
												}}
											/>

											{watchIsCustomBranch ? (
												<FieldText
													require
													{...{
														name: fieldNames.branch,
														register,
														placeholder: "branch",
														label: "branch",
														errors,
													}}
												/>
											) : (
												<BranchSelector
													{...{
														// Controller,
														name: fieldNames.branch,
														register,
														label: "branch",
														errors,
														control,
													}}
												/>
											)}
											<CategorySelector
												{...{
													name: fieldNames.category,
													register,
													label: "category",
													errors,
													control,
												}}
											/>

											<CheckBox
												{...{
													control,

													name: fieldNames.isNewProduct,
													register,
													placeholder: "isNewProduct",
													label: "isNewProduct",
													errors,
												}}
											/>

											<CheckBox
												{...{
													// Controller,\
													control,
													name: fieldNames.isFastDelivery,
													register,
													label: "isFastDelivery",
													errors,
												}}
											/>
											<CheckBox
												{...{
													control,

													name: fieldNames.isNetworkDry,
													register,
													placeholder: "isNetworkDry",
													label: "isNetworkDry",
													errors,
												}}
											/>
											{/* 
                      <CheckBox
                        {...{
                          // Controller,\
                          control,
                          name: fieldNames.isRecommended,
                          register,
                          label: "isRecommended",
                          errors,
                        }}
                      />

                      {isRecommendedState ? (
                        <>
                          <FieldText
                            require
                            {...{
                              name: fieldNames.kdv,
                              register,
                              placeholder: "kdv",
                              label: "kdv",
                              errors,
                              type: "number",
                            }}
                          />
                          <FieldSelect
                            {...{
                              Controller,
                              name: fieldNames.delivery,
                              register,
                              label: "delivery",
                              errors,
                              control,
                              options: DRINK_DATA,
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )} */}

											<GalleryComponent
												isRequired
												{...{
													handleModal: handleGalleryModal,
													selectedPhoto: thumbnail,
													labe: "image",
													name: fieldNames.images,
													Controller,
													control,
													errors,
													register,
												}}
											/>
											{/* <CheckBox
                        {...{
                          // Controller,
                          control,

                          name: fieldNames.serviceCharge,
                          register,
                          label: "serviceCharge",
                          errors,
                        }}
                      /> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 mt-4">
							<div className="">
								{/* <div className="b-1">
                  <h4 className="">{"translations"}</h4>

                </div> */}
								<Row className="b-1">
									<Col lg={6}>
										<h2 className="">translations</h2>
									</Col>
									<Col lg={6} className="d-flex justify-content-end">
										<Button onClick={addNewtranslation} className={"d-flex align-items-center"} size="sm">
											<div className={"gallery-add-title"}>Add translate</div>
											<div className={"ml-1"}>+</div>
										</Button>
									</Col>
								</Row>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form"></div>
										<Controller
											name={fieldNames.translations}
											control={control}
											render={({ field: { value } }) => {
												// console.log({ value });

												return Children.toArray(
													(value || [{ ...defaultData }]).map((_, index) => {
														// const values2 = getValues();

														// function changeHandle(param) {
														// 	console.log({ param });
														// 	let values = value ? [...value] : [{ ...defaultData }];
														// 	values[index][param.name] = param.value;
														// 	onChange(values);
														// }

														// function languageSelectHandler(e) {
														//   changeHandle({ name: "isFree", value: e });
														//   // console.log({ select: params.target.value });
														//   console.log({ e });
														// }

														// function reasonSelectHandler(e) {
														//   let target = e.target;
														//   changeHandle({ name: target.name, value: target.value });
														// }
														// function datePickerHandler(e) {
														//   changeHandle({ name: "startDate", value: e });
														//   let date = new Date(e.toISOString());
														//   // setStartDate(date);
														// }

														// function handleChangeInput(e) {
														// 	let target = e.target;
														// 	console.log({ target });
														// 	changeHandle({ name: target.name, value: target.checked || target.value });
														// }
														// function handleChangeSelect(e) {
														// 	// let target = e.target;
														// 	console.log({ e });
														// 	changeHandle({ name: e.name, value: { label: e.label, value: e.value } });
														// }
														// function handleChangeCheckbox(e) {
														// 	// let target = e.target;
														// 	console.log({ e });
														// 	changeHandle({ name: e.name, value: e.checked || e.value });
														// 	// changeHandle({ name: target.name, value: target.checked || target.value });
														// }

														const removeClick = (index) => {
															let values = getValues();
															if (!values[fieldNames.translations]) return;
															// newValues[fieldNames.episodes].splice(index, 1);
															const newValues = values[fieldNames.translations].filter((param, i) => i !== index);
															// console.log({ newValues });
															setValue(fieldNames.translations, newValues);
														};

														return (
															<Row className="translation-row shadow  p-3 my-3" key={value?.translate_email || index}>
																{index ? (
																	<>
																		<Col lg={6}>
																			<h5>translation{index + 1}</h5>
																		</Col>
																		<Col lg={6}>
																			<div className="d-flex">
																				<div className=" ml-auto">
																					<Button
																						color="danger"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																				<div className="mr-2"></div>
																				{value?.length === index + 1 ? (
																					<Button onClick={addNewtranslation} className={"d-flex align-items-center"} size="sm">
																						<div className={"gallery-add-title"}>Add translate</div>
																						<div className={"ml-1"}>+</div>
																					</Button>
																				) : (
																					""
																				)}
																			</div>
																		</Col>
																		<Col lg={12}>
																			<hr style={{ marginBottom: 0 }} />
																		</Col>
																	</>
																) : (
																	""
																)}
																{/* <Col md={12} lg={5}>
                                  <FormSelectDefaultWithControl change={languageSelectHandler} value={locale.localeLanguage} isRequire isSearchable {...{ control, label: "language", name: "localeLanguage", options: [] }} />
                                </Col> */}

																<FieldText
																	require
																	{...{
																		name: `translations.${index}.name`,
																		placeholder: "name",
																		label: "name ",
																		error: errors.translations?.[index]?.name,

																		Controller,
																		control,
																		register,
																		// value: value?.[index]?.[fieldNames.translate_name],
																		// onChange: handleChangeInput,
																	}}
																/>
																<FieldText
																	require
																	{...{
																		name: `translations.${index}.shortDesc`,
																		placeholder: "shortDescription",
																		label: "shortDescription ",
																		// value: value?.[index]?.[fieldNames.translate_shortDesc],
																		error: errors.translations?.[index]?.shortDescription,

																		Controller,
																		control,
																		register,
																		// onChange: handleChangeInput,
																	}}
																/>
																{/* <FieldText
																	require
																	{...{
																		name: `translations[${index}].listOrderNumber`,
																		placeholder: "listOrderNumber",
																		label: "listOrderNumber ",
																		error: errors.translations?.[index]?.listOrderNumber,
																		// value: value?.[index]?.[fieldNames.translate_name],
																		// onChange: handleChangeInput,
																		control,
																		type: "number",
																	}}
																/> */}
																<FieldText
																	require
																	{...{
																		name: `translations.${index}.desc`,
																		placeholder: "desc",
																		label: "desc ",
																		// value: value?.[index]?.[fieldNames.translate_desc],
																		error: errors.translations?.[index]?.desc,
																		Controller,
																		control,
																		register,
																		// onChange: handleChangeInput,
																	}}
																/>
																<LanguageSelector
																	{...{
																		name: `translations.${index}.language`,
																		label: "language",
																		// value: value?.[index]?.[fieldNames.translate_language],
																		error: errors.translations?.[index]?.language,
																		Controller,
																		control,
																		register,
																		// onChange: (e) => handleChangeSelect({ ...e, name: fieldNames.translate_language }),
																	}}
																/>
															</Row>
														);
													})
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
							<Col md="12 border p-4 mt-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Melo"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<CheckBox
												{...{
													control,
													name: `isMeloProduct`,
													register,
													label: "isMeloProduct",
													errors,
												}}
											/>
											{watchIsMeloProduct ? (
												<>
													<FieldText
														require
														{...{
															placeholder: "meloServiceTimeMinutes",
															label: "meloServiceTimeMinutes",
															register,
															name: `meloServiceTimeMinutes`,
															errors,
														}}
													/>

													<FieldText
														require
														{...{
															placeholder: "meloTelegramChatId",
															label: "meloTelegramChatId",
															register,
															name: `meloTelegramChatId`,
															errors,
														}}
													/>

													{/* <FieldDate
                              {...{
                                control,
                                name: `meloMaxHourMinutes`,
                                register,
                                placeholder: " 11:20 am",
                                label: "meloMaxHourMinutes",
                                errors,
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={5}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              showTimeInput={false}
                            /> */}

													<FieldDate
														{...{
															control,
															name: `meloStartTime`,
															register,
															placeholder: "07:30",
															label: "meloStartTime",
															errors,
														}}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={30}
														timeCaption="Time"
														dateFormat="HH:mm"
														showTimeInput={false}
													/>

													<FieldDate
														{...{
															control,
															name: `meloEndTime`,
															register,
															placeholder: "22:30",
															label: "meloEndTime",
															errors,
														}}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={30}
														timeCaption="Time"
														dateFormat="HH:mm"
														showTimeInput={false}
													/>

													{/* <FieldDate
                              {...{
                                control,
                                name: `meloStartTime`,
                                register,
                                placeholder: "",
                                label: "meloStartTime",
                                errors,
                              }}
                              showTimeSelect
                              timeIntervals={30}
                              // timeCaption="Time"
                              dateFormat="yyyy/MM/dd , HH:mm"
                              showTimeInput={false}
                            />

                            <FieldDate
                              {...{
                                control,
                                name: `meloEndTime`,
                                register,
                                placeholder: "",
                                label: "meloEndTime",
                                errors,
                              }}
                              showTimeSelect
                              timeIntervals={30}
                              // timeCaption="Time"
                              dateFormat="yyyy/MM/dd , HH:mm"
                              showTimeInput={false}
                            /> */}

													<FieldText
														require
														{...{
															placeholder: "meloMaxDays",
															label: "meloMaxDays",
															register,
															name: `meloMaxDays`,
															errors,
														}}
													/>
												</>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</Col>
							<div className="row mt-4">
								<Col md="12  p-3">
									<div className="card">
										{/* <div className="p-4">
                    <h4 className="card-title">{t("except")}</h4>
                  </div> */}

										<Row className="">
											<Col lg={6}>
												<h5 className="text-info">{t("promotion")}</h5>
											</Col>
											{/* <Col lg={6} className="d-flex justify-content-end">
                      <Button onClick={addNewExceptCategory} className={"d-flex align-items-center"} size="sm">
                        <div className={"gallery-add-title"}>
                          {t("add")}
                        </div>
                        <div className={"ml-1"}>
                          +
                        </div>
                      </Button>
                    </Col> */}
										</Row>

										<div className="mt-0">
											<div className="col-12">
												<div className="basic-form"></div>
												<Controller
													name={fieldNames.promotionCodes}
													control={control}
													render={({ field: { onChange, value } }) => {
														console.log({ value });

														return Children.toArray(
															(value || [{}]).map((locale, index) => {
																const values2 = getValues();

																const removeClick = (index) => {
																	let values = getValues();
																	if (!values[fieldNames.promotionCodes]) return;
																	const newValues = values[fieldNames.promotionCodes].filter((param, i) => i !== index);
																	setValue(fieldNames.promotionCodes, newValues);
																};

																return (
																	<Row className="user-row shadow py-3 mt-4" key={value?.email || index}>
																		{index || true ? (
																			<>
																				<Col lg={8}>
																					<h7>
																						{t("promotion")}
																						{index ? `(${index + 1})` : ""}
																					</h7>
																				</Col>
																				<Col lg={4}>
																					<div>
																						<Button
																							color="warning"
																							className={"d-flex align-items-center"}
																							size="sm"
																							onClick={(e) => {
																								e.preventDefault();
																								removeClick(index);
																							}}>
																							remove
																						</Button>
																					</div>
																				</Col>
																				{/* <Col lg={12}>
                                        <hr style={{ marginBottom: 0 }} />
                                      </Col> */}
																			</>
																		) : (
																			""
																		)}

																		<Col xs={12} className="mt-1">
																			<FieldText
																				require
																				{...{
																					placeholder: "code",
																					label: "code ",
																					register,
																					name: `promotionCodes.[${index}].code`,
																					error: errors.promotionCodes?.[index]?.code,
																				}}
																			/>
																			<CheckBox
																				{...{
																					control,
																					name: `promotionCodes.[${index}].isUsed`,
																					error: errors.promotionCodes?.[index]?.isUsed,
																					register,
																					label: "isUsed",
																					errors: errors.promotionCodes?.[index],
																					disabled: !URL_ID,
																				}}
																			/>
																		</Col>
																	</Row>
																);
															})
														);
													}}
												/>
												<Col xs={12} className="mt-3">
													<Button onClick={addNewPromotion} className={"flex text-center w-100"} size="sm">
														<div className={"text-center"}>{t("add")}</div>
													</Button>
												</Col>
											</div>
										</div>

										{/* <div className="card-body">
                    <div className="basic-form">


                      <CategorySelector
                        isMulti

                        {...{
                          name: fieldNames.promotionCodes,
                          register,
                          label: "category",
                          errors,
                          control,
                        }}
                      />

                      <ProductSelector

                        isMulti
                        {...{
                          Controller,
                          name: fieldNames.exceptProducts,
                          register,
                          label: "product",
                          errors,
                          control,
                        }}
                      />




                    </div>
                  </div> */}
									</div>
								</Col>
							</div>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
