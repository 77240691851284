import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import * as productEnum from "containers/Product/enum";
import {
  DRINK_DATA,
  DRINK_DATA_OBJECT,
  INVOICE_DATA,
  INVOICE_DATA_OBJECT,
  LANGUAGE_DATA,
  LANGUAGE_DATA_OBJECT,
  ROLES,
  STATUSES,
  STATUSES_OBJECT,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";
import CategorySelector, { convertCategoryData } from "containers/Category/CategorySelector";
import GalleryComponent from "components/gallery/GalleryComponent";
import LanguageSelector from "containers/Language/LanguageSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss"
import ProductSelector from "containers/Product/ProductSelector";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


const Add = ({ copy }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);

  const navigation = useNavigate();
  let { id } = useParams();
  const URL_ID = id ? id : "";

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [type, setType] = useState(null);
  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const typeOptions = [
    { label: "translation", value: "translation" },
    { label: "Admin", value: "ADMIN" },
  ];
  // console.log({ copy });
  // ----------------------------------------------------------------------------- Fetching Functions
  const creating = async (params) => await axiosClient().patch(api[API_NAME].add, params);
  const updating = async (params) => await axiosClient().put(api[API_NAME].add + "/" + URL_ID, params);
  const getById = async () => await axiosClient().get(api[API_NAME].add + "/" + URL_ID);
  // const getRoles = async () => await axiosClient().get(api["role"].list);
  const getting = async () => await axiosClient().get(api[productEnum.API_NAME].list, { params: { limit: 0 }, });

  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });
  // ------------------------------------------------------- query Get

  const handleSetData = (data) => {
    console.log({ data });
    // data.data.result?.map((x)=>({buyPrice:x.buyPrice}))
    reset({
      products: data.data.result
    })
  }
  const {
    data: { data = {} } = {},

  } = useQueryCustom({
    name: `${productEnum.API_NAME}_get`,
    url: getting,
    onSuccess: handleSetData
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    navigation(newRoute.base);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: URL_ID && !copy ? updating : creating,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };
    // let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
    console.log({ params });

    // handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });

    const resetParam = {
      ...params,

      // image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
    };
    console.log({ resetParam });
    reset(resetParam);
  };
  const dataById = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: (!!URL_ID),
  });

  //  ----------------------------------------- GETTING ROLES ----------------------------------------- //
  // const onSuccessRoles = ({ data }) => {
  //   setRolesOption(convertToLabelValue(data?.result));
  // };
  // const rolesData = useQueryCustom({
  //   name: "rolesData",
  //   url: getRoles,
  //   onSuccess: onSuccessRoles,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });
  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();
    console.log({ values });
    let keyObject = data?.result?.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {})
    let changeProducts = []
    let showProductsData = []
    for (let i = 0; i < values.products.length; i++) {
      const curr = values.products[i];
      const staticCurr = keyObject[curr.id]
      if (+curr.buyPrice != +staticCurr.buyPrice) {

        changeProducts.push(curr)
        showProductsData.push({
          beforePrice: staticCurr.buyPrice,
          product: curr
        })
      }
    }

    if (!changeProducts?.length) return navigation(newRoute.base);
    MySwal.fire({
      title: " Please check carefully!!",
      html: (
        <div className="card">
          <div className="py-4">
            <ul className="list-ticked">
              {showProductsData?.map(({ product, beforePrice }, i) => {
                return (
                  <li key={product.id} className="d-flex text-nowrap justify-content-start align-items-center mb-2">
                    <div style={{ width: "20px" }}>{i + 1}-</div>   <img width={30} src={product.images} />
                    {" "}  <strong style={{ minWidth: "100px" }} className="d-flex  text-start">
                      {product?.name}
                    </strong>{" "}
                    ({beforePrice})
                    <div className="mx-2">{":"}</div>
                    <br />
                    <div className="text-start">

                      {

                        product.buyPrice
                        // value?.value || (value?.[0]?.label && value?.map((x) => x.label)) || value
                      }</div>{" "}
                  </li>
                );
              })}
            </ul>
          </div>
          <h4 className="card-title">Are you sure?!</h4>
          {/* <p className="card-description">If you confirm, the changes will be applied</p> */}
        </div>
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {


        const reqData = {
          products: changeProducts.map((x) => ({
            productId: x.id,
            buyPrice: x.buyPrice,
          }))
          // ...values,
          // productId: values.productId?.value,
        };
        console.log({ reqData, values, keyObject, changeProducts });
        mutate(reqData);

        // const requestData = {
        //   ...values,
        //   hotels: !values.hotels?.find(x => x.value === "all") ? values.hotels?.map((x) => x?.value) : undefined,
        //   category: !values.category?.find(x => x.value === "all") ? values.category?.map((x) => x?.value) : undefined,
        //   product:
        //     //  !values.product?.find(x => x.value === "all")
        //     values.product?.value !== "all" ?
        //       values.product?.value
        //       //  ? values.product?.map((x) => x?.value)
        //       : undefined,
        //   delivery: values.delivery?.value !== "all" ? values.delivery?.value : undefined,
        //   operation: values.operation?.value !== "all" ? values.operation?.value : undefined,
        //   operationSide: values.operationSide?.value !== "all" ? values.operationSide?.value : undefined,
        //   startTime: values?.startTime && toUKTimezone(values?.startTime).format("hh:mm A"),
        //   endTime: values?.endTime && toUKTimezone(values?.endTime).format("hh:mm A"),
        // };
        // console.log("reqData", { requestData });
        // mutate(requestData);
      }

      // console.log({ willX });
      // return MySwal.fire(<p>Shorthand works too</p>)
    });

  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `Back To ${COMPONENT_NAMES[0]} List`,
    link: newRoute.base + newRoute.list,
  };

  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard.base },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    {
      title: URL_ID ? `Edit / ${dataById?.data?.data?.name} ` : "Add",
    },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();
  console.log({ data, dataById, values }, "Add");
  console.log({ URL_ID });
  console.log({ errors }, "errors");

  // ----------------------------------------------------------------------------- UseEffects

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.images, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };

  const defaultData = { translate_name: "" };

  const addNewtranslation = () => {
    const values = getValues();
    const newData = values[fieldNames.translations]
      ? [...values[fieldNames.translations], { ...defaultData }]
      : [{ ...defaultData }, { ...defaultData }];
    console.log({ newData });
    setValue(fieldNames.translations, newData);
    // reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
  };

  const isRecommendedState = watch(fieldNames.isRecommended);

  return (
    <div>
      <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <PageTitle title={URL_ID && !copy ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1 d-flex">
                  <h4 className="">{"Basic Information"}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <Controller
                        name={fieldNames.products}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return value?.map((x, i) => (
                            <div className="py-3 border-top">
                              <div className="d-flex align-items-center mb-3">
                                {i + 1}-   <img width={30} src={x.images} />    <span className="">{x?.name}</span>
                              </div>


                              <FieldText
                                require
                                {...{
                                  name: `products[${i}].buyPrice`,
                                  register,
                                  placeholder: "buyPrice",
                                  label: "buyPrice",
                                  errors,
                                }}
                              />
                            </div>
                          )
                          )
                        }} />

                      {/* <ProductSelector
                        {...{
                          Controller,
                          name: fieldNames.productId,
                          register,
                          label: "product",
                          errors,
                          control,
                        }}
                      /> */}



                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Col>
          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{"Actions"}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      {/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

                      {/* <hr /> */}

                      <Button
                        block
                        type="success"
                        htmlType="submit"
                        className={`btn btn-success btn-sm`}
                        loading={isLoading}
                      >
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>




          </Col>
        </div>
      </form >
    </div >
  );
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
